// import React, { useEffect, useState } from "react";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import "./styles.scss";
// import { formatNumberInternational } from "../../../helpers/capatalizeLetters";

// const CustomDatagrid = ({
//   rows,
//   columns,
//   CustomNoRowsOverlay,
//   getRowId,
//   height,
//   tHeight = "31.25rem",
//   checkRequired = false,
//   onCellClick,
//   hoverColor,
//   loader = false,
//   isRowsVisible = false,
//   isValueVisible = false,
//   setSelectedRowIds = () => {},
// }) => {
//   const isSmallScreen = useMediaQuery("(max-width:1550px)");

//   // Calculate the sum of a particular column
//   const calculateSum = (rows, field) => {
//     return rows?.reduce((sum, row) => {
//       const value = Number(row[field]) || 0; // Ensure the value is a number
//       return sum + value;
//     }, 0);
//   };

//   // Example: Sum for a column named "amount"
//   const totalFaceValue = calculateSum(rows, "face_value"); // Replace "amount" with your column field
//   const totalCostValue = calculateSum(rows, "cost_value"); // Replace "amount" with your column field

//   const modifiedColumns = columns?.map((column, index) => ({
//     ...column,
//     flex: column.flex,
//     hide: isSmallScreen && index > 1,
//     sortable: column?.sortable,
//   }));

//   const dynamicHeight = isRowsVisible ? "auto" : tHeight;
//   const getRowHeight = () => (isSmallScreen ? height : height || 52);

//   return (
//     <Box
//       sx={{
//         minHeight: tHeight,
//         marginBottom: "1.5rem",
//         transition: "height 0.3s ease-in-out",
//         height: dynamicHeight,
//         fontFamily: "Instrument Sans",
//       }}
//     >
//       <div
//         className={`${
//           isRowsVisible && !loader ? "" : "table-div"
//         } h-100 mb-3 mt-3`}
//         style={{
//           height: isRowsVisible && !loader ? "100%" : "0",
//           // position: "relative",
//         }}
//       >
//         <DataGrid
//           rows={rows}
//           columns={modifiedColumns}
//           getRowId={getRowId}
//           initialState={{
//             pagination: {
//               paginationModel: {
//                 pageSize: 100,
//               },
//             },
//           }}
//           getCellClassName={(params) => {
//             if (onCellClick) {
//               if (params.field === "actions") {
//                 return "";
//               }
//               return "cursor-pointer";
//             }
//           }}
//           onCellClick={onCellClick}
//           checkboxSelection={checkRequired}
//           loading={loader}
//           onRowSelectionModelChange={(ids) => {
//             if (setSelectedRowIds) {
//               setSelectedRowIds(ids);
//             }
//           }}
//           getRowHeight={getRowHeight}
//           disableRowSelectionOnClick
//           DisableMultipleRowSelection={true}
//           hideFooterPagination={false}
//           hideFooter
//           disableColumnMenu
//           className="custom-scrollbar"
//           sx={{
//             "&.MuiDataGrid-root": {
//               border: "none",
//               fontFamily: "Instrument Sans",
//               fontSize: isSmallScreen ? "11px" : "11px",
//             },
//             "& .MuiDataGrid-columnHeader": {
//               backgroundColor: "#F1F3F5",
//               padding: isSmallScreen ? "8px" : "11px",
//             },
//             "& .MuiDataGrid-cell": {
//               fontSize: "11px",
//               color: "#1B242C",
//               whiteSpace: "wrap",
//             },
//             "& .MuiDataGrid-columnHeaderTitle": {
//               fontWeight: "600",
//               color: "#555F6D",
//               fontSize: isSmallScreen ? "10px" : "11px",
//               whiteSpace: "normal",
//             },
//             "& .MuiCheckbox-root": {
//               "& .MuiSvgIcon-root": {
//                 fill: "#9EA8B3",
//               },
//               "&.Mui-checked .MuiSvgIcon-root": {
//                 fill: "#146EF5",
//               },
//             },
//             "& .MuiDataGrid-row:hover": {
//               background: hoverColor,
//             },
//           }}
//           slots={{
//             noRowsOverlay: CustomNoRowsOverlay,
//           }}
//           disableColumnFilter
//         />
//       </div>
//       {/* Display the total sum */}
//       {isValueVisible && (
//         <Box
//           sx={{
//             marginBottom: "1rem",
//             fontWeight: "bold",
//             gap: "1rem",
//             fontSize: isSmallScreen ? "12px" : "14px",
//             // position: "absolute",
//             // left: "43%",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             }}
//           >
//             <div style={{ marginRight: "10px" }}>
//               Face Value:{" "}
//               {formatNumberInternational(totalFaceValue?.toFixed(2))}
//             </div>
//             <div>
//               Cost Value:{" "}
//               {formatNumberInternational(totalCostValue?.toFixed(2))}
//             </div>
//           </div>
//         </Box>
//       )}
//     </Box>
//   );
// };

// export default CustomDatagrid;

"use client";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import "ag-grid-community/styles/ag-theme-alpine.css"; // Only include one theme CSS file
import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useMemo, useState } from "react";
import { NumberFormatAndAddZero } from "../../../helpers/capatalizeLetters";
import "./custom.scss";

ModuleRegistry.registerModules([AllCommunityModule]);

const CustomDatagrid = ({
  rows,
  getRowId,
  columns,
  loader = false,
  tHeight = "31.25rem",
  CustomNoRowsOverlay,
  height = 52,
  onCellClick,
  smallTable = false,
  isRowsVisible = false,
  isValueVisible = false,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:1550px)");
  const defaultColDef = {
    flex: 1,
  };
  const [selectedFaceValue, setSelectedFaceValue] = useState();
  const [selectedCostValue, setSelectedCostValue] = useState();

  const dynamicHeight = isRowsVisible ? "auto" : tHeight;

  const gridStyle = {
    width: "100%",
    height: dynamicHeight,
    overflow: "auto",
    border: "none",
    minHeight: tHeight,
    fontFamily: "Instrument Sans",
  };

  const getRowHeight = () => (isSmallScreen ? height : height || 52);

  const calculateSum = (rows, field) => {
    return rows?.reduce((sum, row) => {
      const value = Number(row[field]) || 0;
      return sum + value;
    }, 0);
  };

  const totalFaceValue = calculateSum(rows, "face_value");
  const totalCostValue = calculateSum(rows, "cost_value");

  const CustomLoadingOverlay = (props) => {
    return (
      <div
        className="w-100  d-flex align-items-center justify-content-center"
        style={{ height: tHeight ? tHeight : "70vh" }}
      >
        <span
          className="spinner-border spinner-border-lr ms-2"
          style={{ color: "#3b424a", width: "40px", height: "40px" }}
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    );
  };

  const modifiedColumns = columns?.map((column, index) => ({
    ...column,
    flex: column.flex,
    sortable: column?.sortable,
  }));

  const rowSelection = useMemo(() => {
    return {
      mode: "multiRow",
    };
  }, []);

  let selectedRes = [];
  const onRowSelected = useCallback((event) => {
    if (isValueVisible && event?.node?.__selected) {
      selectedRes.push(event?.data);
    } else if (isValueVisible && !event?.node?.__selected) {
      selectedRes = selectedRes.filter(
        (item: any) => item._id !== event?.data?._id,
      );
    }
    const totalFaceValue = calculateSum(selectedRes, "face_value");
    const totalCostValue = calculateSum(selectedRes, "cost_value");
    setSelectedFaceValue(totalFaceValue);
    setSelectedCostValue(totalCostValue);
  }, []);

  return (
    <>
      <div
        style={gridStyle}
        className={`ag-theme-alpine ${smallTable && "ag-container-small"}`}
      >
        <AgGridReact
          rowData={rows}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          domLayout="autoHeight"
          onCellClicked={onCellClick}
          rowHeight={52}
          loading={loader}
          getRowClass={(params) => {
            if (onCellClick) {
              if (params.field === "actions") {
                return "";
              }
              return "cursor-pointer";
            }
          }}
          rowSelection={isValueVisible ? rowSelection : ""}
          onRowSelected={onRowSelected}
          noRowsOverlayComponent={CustomNoRowsOverlay}
          noRowsOverlayComponentParams={{
            message: CustomNoRowsOverlay,
          }}
          loadingOverlayComponent={CustomLoadingOverlay}
          loadingOverlayComponentParams={{
            message: CustomNoRowsOverlay,
          }}
        />
      </div>
      {isValueVisible && (
        <Box
          sx={{
            marginBottom: "1rem",
            fontWeight: "bold",
            gap: "1rem",
            fontSize: "14px",
            // position: "absolute",
            // left: "43%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              Face Value:{" "}
              {selectedFaceValue
                ? NumberFormatAndAddZero(selectedFaceValue?.toFixed(2))
                : NumberFormatAndAddZero(totalFaceValue?.toFixed(2))}
            </div>
            <div>
              Cost Value:{" "}
              {selectedCostValue
                ? NumberFormatAndAddZero(selectedCostValue?.toFixed(2))
                : NumberFormatAndAddZero(totalCostValue?.toFixed(2))}
            </div>
          </div>
        </Box>
      )}
    </>
  );
};

export default CustomDatagrid;
