import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import {
  breakStringInMiddle,
  NumberFormatAndAddZero,
} from "../../../../../../helpers/capatalizeLetters";
import { findRateForMonth } from "../../../../../../helpers/finalDateCalculate";
import { getData, patchData, postData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomButton";
import { CustomDropDown, CustomInput } from "../../../../../atoms/CustomInput";
import { Heading } from "../../../../../atoms/Heading";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import PrimitiveDropdown from "../../../../../atoms/PrimitiveDropdown";
import ScrollableTable from "../../../../../atoms/ScrollTable";
import InfoIcon from "../../../../../icons/InfoIcon";
import Breadcrumb from "../../../../../molecules/Breadcrumb";
import style from "./index.module.scss";

const ReviewView = () => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [fundingDueData, setFundingDueData] = useState([]);
  const [fundingDetails, setFundingDetails] = useState({
    client_id: "",
    obligor_id: "",
    trans_type: "",
  });
  const [manualAdv, setManualAdv] = useState(false);
  const [autoApplyInterest, setAutoApplyInterest] = useState(false);
  const [autoApplyCost, setAutoApplyCost] = useState(false);
  const [autoApplyFull, setAutoApplyFull] = useState(false);
  const [reservedAmount, setReservedAmount] = useState();
  const [paymentInfoData, setPaymentInfoData] = useState();
  const [assignmentType, setAssigmentType] = useState("Assign Singular");
  const [selectedCalcluationDate, setSelectedCalcluationDate] = useState();
  const [isLoadind, setIsLoading] = useState(false);

  const createDocRecords = (num) => {
    return Array.from({ length: num }, () => ({
      paymentApp: "",
      postAppStatus: "",
      paymentType: "",
      currency: "",
    }));
  };

  const GetPaymentDataById = async () => {
    try {
      const res = await getData({
        endpoint: "/payment/getPaymentById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setPaymentInfoData(res?.data);
      setSelectedCalcluationDate(moment.utc(res?.data?.date));
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (id) {
      GetPaymentDataById();
    }
  }, [id]);

  const [manualAdvData, setManualAdvData] = useState(createDocRecords(0));
  const [fundingIntialData, setFundingIntialData] = useState([]);

  const getFundingDues = async () => {
    try {
      const res = await getData({
        endpoint: "funding/geFundingClientIdAndSupplierId",
        params: {
          client_id: fundingDetails?.client_id,
          obligor_id: fundingDetails?.supplier_id,
          trans_type: fundingDetails?.trans_type,
        },
        token: cookies.t,
      });
      setFundingIntialData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getupdatedInterest = async () => {
    const updatedFunds = [];
    for (const funding of fundingIntialData || []) {
      let interestData;
      if (funding?.pricingDetails?.rate_type === "Floating") {
        const tempTotalInterest = findRateForMonth(
          funding?.ratesDetails?.[0]?.rates,
          new Date(funding?.funded_date),
        );
        interestData = calculateDailyCompoundInterest({
          principal: parseFloat(funding.cost_value),
          annualRate:
            parseFloat(tempTotalInterest?.base_rate) +
            parseFloat(funding?.pricingDetails?.top_rate),
          startDate: moment
            .utc(funding?.funded_date)
            .add(1, "day")
            .toISOString(),
          endDate: selectedCalcluationDate,
        });
      } else {
        interestData = calculateDailyCompoundInterest({
          principal: parseFloat(funding?.cost_value),
          annualRate: parseFloat(funding?.pricingDetails?.top_rate),
          startDate: moment
            .utc(funding?.funded_date)
            .add(1, "day")
            .toISOString(),
          endDate: selectedCalcluationDate,
        });
      }
      updatedFunds.push({
        ...funding,
        dailyInterest: interestData?.interest,
      });
    }
    setFundingDueData(updatedFunds);
  };

  useEffect(() => {
    if (fundingIntialData?.length) {
      getupdatedInterest(fundingIntialData);
    }
  }, [selectedCalcluationDate, fundingIntialData]);

  const calculateDailyCompoundInterest = ({
    principal,
    annualRate,
    startDate,
    endDate,
  }) => {
    const start = new Date(
      Date.UTC(
        new Date(startDate).getUTCFullYear(),
        new Date(startDate).getUTCMonth(),
        new Date(startDate).getUTCDate(),
      ),
    );

    const end = new Date(
      Date.UTC(
        new Date(endDate).getUTCFullYear(),
        new Date(endDate).getUTCMonth(),
        new Date(endDate).getUTCDate(),
      ),
    );

    const dailyInterestRate = annualRate / 36000;

    let currentDate = new Date(start);
    const dailyInterest = principal * dailyInterestRate;
    let interest = 0;
    while (currentDate <= end) {
      interest += dailyInterest;
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return {
      endDate: end.toLocaleDateString("en-GB"), // End date in DD/MM/YYYY format
      interest: interest?.toFixed(2), // Total interest accrued
      totalAmount: (principal + interest)?.toFixed(2), // Principal + Interest
    };
  };

  useEffect(() => {
    if (fundingDetails?.client_id) {
      getFundingDues();
    }
  }, [fundingDetails, id]);

  useEffect(() => {
    if (paymentInfoData?.client_id) {
      if (
        paymentInfoData?.obligor_id &&
        paymentInfoData?.transaction_type === "Obligor"
      ) {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: paymentInfoData?.transaction_type,
        }));
      } else if (paymentInfoData?.transaction_type === "Client") {
        setFundingDetails(() => ({
          client_id: paymentInfoData?.client_id,
          supplier_id: paymentInfoData?.obligor_id,
          trans_type: paymentInfoData?.transaction_type,
        }));
      }
    }
  }, [paymentInfoData, id]);
  console.log("assignmentType", assignmentType);

  const handleConfirmAndApply = async () => {
    if (assignmentType === "Manual") {
      setIsLoading(true);
      const updatedManualAdvData = manualAdvData?.map((item) => {
        const previousPayments = item.paymentHistory || [];
        const newPaymentEntry = {
          paymentAmount: item?.paymentApp,
          paymentState: item?.postAppStatus,
          currency: item?.currency,
          paydate: paymentInfoData?.date,
          paymentType: item?.paymentType,
          payment_id: paymentInfoData?._id,
        };
        return {
          ...item,
          paymentApp: item?.paymentApp,
          postAppStatus: item?.postAppStatus,
          paymentType: newPaymentEntry.paymentType,
          paymentId: paymentInfoData?._id,
          paymentHistory: [...previousPayments, newPaymentEntry],
        };
      });
      const totalPaymentApp = manualAdvData.reduce((sum, item) => {
        // Convert paymentApp to a number if it exists, otherwise default to 0
        const paymentValue = parseFloat(item.paymentApp) || 0;
        return sum + paymentValue;
      }, 0);
      try {
        const res = await patchData({
          endpoint: "funding/updatePaymentHistory",
          data: updatedManualAdvData,
          token: cookies.t,
        });
        if (res) {
          const remainngAmt =
            parseInt(paymentInfoData?.amount) - parseFloat(totalPaymentApp);
          if (parseFloat(remainngAmt) <= parseFloat(0.0)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                toast.success("Payment Details Updated Successfully", {
                  duration: 1000,
                  style: confimationStyles,
                });
                setIsLoading(false);
                navigate("/dashboard/payments");
              }
            } catch (err) {
              console.log("err", err);
            }
          } else if (parseFloat(remainngAmt) >= parseFloat(0.0)) {
            try {
              const res = await patchData({
                endpoint: "payment/paymentUpdate",
                token: cookies.t,
                params: { id: paymentInfoData?._id },
                data: {
                  ...paymentInfoData,
                  amount:
                    parseFloat(paymentInfoData?.amount) -
                    parseFloat(reservedAmount),
                  remainingAmount: reservedAmount,
                  status: "Assigned",
                },
              });
              if (res) {
                const createRes = await postData({
                  endpoint: "payment/create",
                  token: cookies.t,
                  data: {
                    amount: reservedAmount,
                    remainingAmount: reservedAmount,
                    status: "Reserves",
                    client_id: paymentInfoData?.client_id,
                    client_name: paymentInfoData?.client_name,
                    obligor_id: paymentInfoData?.obligor_id,
                    obligor_name: paymentInfoData?.obligor_name,
                    currency: paymentInfoData?.currency,
                    date: paymentInfoData?.date,
                    transaction_type: paymentInfoData?.transaction_type,
                  },
                });
                if (createRes) {
                  setIsLoading(false);
                  toast.success("Payment Details Updated Successfully", {
                    duration: 1000,
                    style: confimationStyles,
                  });
                  navigate("/dashboard/payments");
                }
              }
            } catch (err) {
              console.log("err", err);
            }
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      if (manualAdv) {
        setIsLoading(true);
        try {
          const res = await patchData({
            endpoint: "funding/updatePaymentHistory",
            data: manualAdvData,
            token: cookies.t,
          });
          if (res) {
            if (parseInt(reservedAmount) <= parseFloat(0.0)) {
              try {
                const res = await patchData({
                  endpoint: "payment/paymentUpdate",
                  token: cookies.t,
                  params: { id: paymentInfoData?._id },
                  data: {
                    ...paymentInfoData,
                    remainingAmount: reservedAmount,
                    status: "Assigned",
                  },
                });
                if (res) {
                  toast.success("Payment Details Updated Successfully", {
                    duration: 1000,
                    style: confimationStyles,
                  });
                  setIsLoading(false);
                  navigate("/dashboard/payments");
                }
              } catch (err) {
                console.log("err", err);
              }
            } else if (parseInt(reservedAmount) >= parseFloat(0.0)) {
              try {
                const res = await patchData({
                  endpoint: "payment/paymentUpdate",
                  token: cookies.t,
                  params: { id: paymentInfoData?._id },
                  data: {
                    ...paymentInfoData,
                    remainingAmount: reservedAmount,
                    status: "Reserves",
                  },
                });
                if (res) {
                  const createRes = await postData({
                    endpoint: "payment/create",
                    token: cookies.t,
                    data: {
                      amount: reservedAmount,
                      remainingAmount: reservedAmount,
                      status: "Reserves",
                      client_id: paymentInfoData?.client_id,
                      client_name: paymentInfoData?.client_name,
                      obligor_id: paymentInfoData?.obligor_id,
                      obligor_name: paymentInfoData?.obligor_name,
                      currency: paymentInfoData?.currency,
                      date: paymentInfoData?.date,
                      transaction_type: paymentInfoData?.transaction_type,
                    },
                  });
                  if (createRes) {
                    toast.success("Payment Details Updated Successfully", {
                      duration: 1000,
                      style: confimationStyles,
                    });
                    setIsLoading(false);
                    navigate("/dashboard/payments");
                  }
                }
              } catch (err) {
                console.log("err", err);
              }
            }
          }
        } catch (err) {
          console.log("err", err);
        }
      } else if (autoApplyInterest) {
        setIsLoading(true);
        try {
          const res = await patchData({
            endpoint: "funding/updatePaymentHistory",
            data: manualAdvData,
            token: cookies.t,
          });
          if (res) {
            if (parseInt(reservedAmount) <= parseFloat(0.0)) {
              try {
                const res = await patchData({
                  endpoint: "payment/paymentUpdate",
                  token: cookies.t,
                  params: { id: paymentInfoData?._id },
                  data: {
                    ...paymentInfoData,
                    remainingAmount: reservedAmount,
                    status: "Assigned",
                  },
                });
                if (res) {
                  toast.success("Payment Details Updated Successfully", {
                    duration: 1000,
                    style: confimationStyles,
                  });
                  setIsLoading(false);
                  navigate("/dashboard/payments");
                }
              } catch (err) {
                console.log("err", err);
              }
            } else if (parseInt(reservedAmount) >= parseFloat(0.0)) {
              try {
                const res = await patchData({
                  endpoint: "payment/paymentUpdate",
                  token: cookies.t,
                  params: { id: paymentInfoData?._id },
                  data: {
                    ...paymentInfoData,
                    remainingAmount: reservedAmount,
                    status: "Reserves",
                  },
                });
                if (res) {
                  const createRes = await postData({
                    endpoint: "payment/create",
                    token: cookies.t,
                    data: {
                      amount: reservedAmount,
                      remainingAmount: reservedAmount,
                      status: "Reserves",
                      client_id: paymentInfoData?.client_id,
                      client_name: paymentInfoData?.client_name,
                      obligor_id: paymentInfoData?.obligor_id,
                      obligor_name: paymentInfoData?.obligor_name,
                      currency: paymentInfoData?.currency,
                      date: paymentInfoData?.date,
                      transaction_type: paymentInfoData?.transaction_type,
                    },
                  });
                  if (createRes) {
                    toast.success("Payment Details Updated Successfully", {
                      duration: 1000,
                      style: confimationStyles,
                    });
                    setIsLoading(false);
                    navigate("/dashboard/payments");
                  }
                }
              } catch (err) {
                console.log("err", err);
              }
            }
          }
        } catch (err) {
          console.log("err", err);
        }
      } else if (autoApplyFull) {
        setIsLoading(true);
        try {
          const res = await patchData({
            endpoint: "funding/updatePaymentHistory",
            data: manualAdvData,
            token: cookies.t,
          });
          if (res) {
            if (parseFloat(0.0) <= parseInt(reservedAmount)) {
              try {
                const res = await patchData({
                  endpoint: "payment/paymentUpdate",
                  token: cookies.t,
                  params: { id: paymentInfoData?._id },
                  data: {
                    ...paymentInfoData,
                    remainingAmount: reservedAmount,
                    status: "Assigned",
                  },
                });
                if (res) {
                  toast.success("Payment Details Updated Successfully", {
                    duration: 1000,
                    style: confimationStyles,
                  });
                  setIsLoading(false);
                  navigate("/dashboard/payments");
                }
              } catch (err) {
                console.log("err", err);
              }
            } else if (parseInt(reservedAmount) >= parseFloat(0.0)) {
              try {
                const res = await patchData({
                  endpoint: "payment/paymentUpdate",
                  token: cookies.t,
                  params: { id: paymentInfoData?._id },
                  data: {
                    ...paymentInfoData,
                    amount:
                      parseFloat(paymentInfoData?.amount) -
                      parseFloat(reservedAmount),
                    remainingAmount: reservedAmount,
                    status: "Assigned",
                  },
                });
                if (res) {
                  const createRes = await postData({
                    endpoint: "payment/create",
                    token: cookies.t,
                    data: {
                      amount: reservedAmount,
                      remainingAmount: reservedAmount,
                      status: "Reserves",
                      client_id: paymentInfoData?.client_id,
                      client_name: paymentInfoData?.client_name,
                      obligor_id: paymentInfoData?.obligor_id,
                      obligor_name: paymentInfoData?.obligor_name,
                      currency: paymentInfoData?.currency,
                      date: paymentInfoData?.date,
                      transaction_type: paymentInfoData?.transaction_type,
                    },
                  });
                  if (createRes) {
                    toast.success("Payment Details Updated Successfully", {
                      duration: 1000,
                      style: confimationStyles,
                    });
                    setIsLoading(false);
                    navigate("/dashboard/payments");
                  }
                }
              } catch (err) {
                console.log("err", err);
              }
            }
          }
        } catch (err) {
          console.log("err", err);
        }
      }
    }
  };

  // const updateFundingData = (
  //   fundingDueData,
  //   paymentInfoData,
  //   isFull = false,
  //   isInterest = false,
  // ) => {
  //   let remainingAmount = parseFloat(paymentInfoData?.amount) || 0;

  //   const updatedData = fundingDueData.map((item) => {
  //     const totalValue = isFull
  //       ? parseFloat(item?.cost_value) + parseFloat(item?.face_value)
  //       : isInterest
  //       ? parseFloat(item?.face_value)
  //       : parseFloat(item?.cost_value);

  //     if (remainingAmount >= totalValue) {
  //       remainingAmount -= totalValue;
  //       return {
  //         ...item,
  //         paymentApp: totalValue.toFixed(2),
  //         postAppStatus: isFull ? "Close" : "open",
  //         paymentType: isInterest
  //           ? "Interest"
  //           : isFull
  //           ? "PIK-Interest"
  //           : "Principle",
  //         paymentId: paymentInfoData?._id,
  //       };
  //     } else if (remainingAmount > 0) {
  //       const currentAmount = remainingAmount;
  //       remainingAmount = 0;
  //       return {
  //         ...item,
  //         paymentApp: currentAmount.toFixed(2),
  //         postAppStatus: "open",
  //         paymentType: isInterest
  //           ? "Interest"
  //           : isFull
  //           ? "PIK-Interest"
  //           : "Principle",
  //         paymentId: paymentInfoData?._id,
  //       };
  //     } else {
  //       return {
  //         ...item,
  //         paymentApp: 0,
  //         postAppStatus: "Open",
  //         paymentType: isInterest
  //           ? "Interest"
  //           : isFull
  //           ? "PIK-Interest"
  //           : "Principle",
  //         paymentId: paymentInfoData?._id,
  //       };
  //     }
  //   });

  //   return { updatedData, remainingAmount: remainingAmount.toFixed(2) };
  // };

  const updateFundingData = ({
    fundingDueData,
    paymentInfoData,
    isFull = false,
    isInterest = false,
    isCost = false,
  }) => {
    let remainingAmount = parseFloat(paymentInfoData?.amount) || 0;

    const updatedData = fundingDueData.map((item) => {
      const totalValue =
        isFull &&
        (item?.transaction_type === "Note" ||
          item?.transaction_type === "Obligor")
          ? parseFloat(item?.cost_value) + parseFloat(item?.dailyInterest)
          : isInterest
          ? parseFloat(item?.dailyInterest)
          : parseFloat(item?.cost_value);

      const previousPayments = item.paymentHistory || [];
      const newPaymentEntry = {
        paymentAmount: "0.00",
        paymentState: "open",
        currency: item?.currency,
        paydate: paymentInfoData?.date,
        paymentType: isInterest
          ? "Interest"
          : isFull
          ? "Principle"
          : isCost
          ? "cost"
          : "N/A",
        payment_id: paymentInfoData?._id,
      };

      if (remainingAmount >= totalValue) {
        remainingAmount -= totalValue;
        newPaymentEntry.paymentAmount = totalValue?.toFixed(2);
        newPaymentEntry.paymentState = isFull ? "close" : "open";

        return {
          ...item,
          paymentApp: totalValue?.toFixed(2),
          postAppStatus: isFull ? "Close" : "open",
          paymentType: newPaymentEntry.paymentType,
          paymentId: paymentInfoData?._id,
          paymentHistory: [...previousPayments, newPaymentEntry],
        };
      } else if (remainingAmount > 0) {
        const currentAmount = remainingAmount;
        remainingAmount = 0;
        newPaymentEntry.paymentAmount = currentAmount?.toFixed(2);
        newPaymentEntry.paymentState = "open";

        return {
          ...item,
          paymentApp: currentAmount?.toFixed(2),
          postAppStatus: "open",
          paymentType: newPaymentEntry.paymentType,
          paymentId: paymentInfoData?._id,
          paymentHistory: [...previousPayments, newPaymentEntry],
        };
      } else {
        // No new payment applied; just add the previous payments
        newPaymentEntry.paymentAmount = "0.00";
        newPaymentEntry.paymentState = "open";

        return {
          ...item,
          paymentApp: 0,
          postAppStatus: "Open",
          paymentType: newPaymentEntry.paymentType,
          paymentId: paymentInfoData?._id,
          paymentHistory: previousPayments, // Keep existing history without adding a new entry
        };
      }
    });

    return { updatedData, remainingAmount: remainingAmount?.toFixed(2) };
  };

  // Update Auto Apply Interest
  const updateAutoInterest = async () => {
    const { updatedData, remainingAmount } = updateFundingData({
      fundingDueData,
      paymentInfoData,
      isFull: false,
      isInterest: true,
    });
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  // Handle Apply Interest Toggle
  const handleApplyInterest = () => {
    setAutoApplyInterest((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoInterest();
  };

  // Update Auto Apply Cost
  const updateAutoCost = async () => {
    const { updatedData, remainingAmount } = updateFundingData({
      fundingDueData,
      paymentInfoData,
      isFull: false,
      isInterest: false,
      isCost: true,
    });
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  // Handle Auto Apply Cost Toggle
  const handleAutoApplyCost = () => {
    setAutoApplyCost((prev) => !prev);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoCost();
  };

  // Update Auto Apply Full
  const updateAutoFull = async () => {
    const { updatedData, remainingAmount } = updateFundingData({
      fundingDueData,
      paymentInfoData,
      isFull: true,
      isInterest: false,
    });
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  // Handle Auto Apply Full Toggle
  const handleAutoApplyFull = () => {
    setAutoApplyFull(true);
    setManualAdvData(() => createDocRecords(fundingDueData?.length));
    updateAutoFull();
  };

  const updateDefaultAutoApplyFull = ({ fundingDueData, paymentInfoData }) => {
    let remainingAmount = parseFloat(paymentInfoData?.amount);

    const updatedData = [...fundingDueData];
    const item = updatedData[0];
    const totalValue =
      parseFloat(item?.cost_value) + parseFloat(item?.dailyInterest);

    // Check for previous payments in paymentHistory
    const previousPayments = item.paymentHistory || [];
    const previousPaymentsTotal = previousPayments.reduce((acc, payment) => {
      return acc + parseFloat(payment.paymentAmount || 0);
    }, 0);

    // Adjust total based on previous payments
    const adjustedTotal = totalValue - previousPaymentsTotal;

    let newPaymentEntry = {
      paymentAmount: remainingAmount?.toFixed(2),
      paymentState: "open",
      paymentType: "assign-singular",
      payment_id: paymentInfoData?._id,
      currency: item?.currency,
      paydate: paymentInfoData?.date,
    };

    if (remainingAmount >= adjustedTotal) {
      remainingAmount -= adjustedTotal;
      newPaymentEntry.paymentAmount = adjustedTotal?.toFixed(2);
      newPaymentEntry.paymentState = "close";

      updatedData[0] = {
        ...item,
        paymentApp: adjustedTotal?.toFixed(2),
        postAppStatus: "Close",
        paymentType: "PIK-Interest",
        paymentId: paymentInfoData?._id,
        paymentHistory: [...previousPayments, newPaymentEntry],
      };
    } else if (remainingAmount > 0) {
      const currentAmount = remainingAmount;
      remainingAmount = 0;
      newPaymentEntry.paymentAmount = currentAmount?.toFixed(2);
      newPaymentEntry.paymentState = "open";

      updatedData[0] = {
        ...item,
        paymentApp: currentAmount?.toFixed(2),
        postAppStatus: "Open",
        paymentType: "PIK-Interest",
        paymentId: paymentInfoData?._id,
        paymentHistory: [...previousPayments, newPaymentEntry],
      };
    } else {
      newPaymentEntry.paymentAmount = "0.00";
      newPaymentEntry.paymentState = "open";
      newPaymentEntry.paymentType = "Interest";

      updatedData[0] = {
        ...item,
        paymentApp: previousPaymentsTotal?.toFixed(2),
        postAppStatus: "Open",
        paymentType: "Interest",
        paymentId: paymentInfoData?._id,
        paymentHistory: previousPayments, // No new entry as no new payment was applied
      };
    }

    return { updatedData, remainingAmount: remainingAmount?.toFixed(2) };
  };

  const assignSingular = () => {
    setAutoApplyFull(true);
    setManualAdvData(() => createDocRecords(1));
    const { updatedData, remainingAmount } = updateDefaultAutoApplyFull({
      fundingDueData,
      paymentInfoData,
    });
    setManualAdvData(updatedData);
    setReservedAmount(remainingAmount);
  };

  useEffect(() => {
    if (paymentInfoData && fundingDueData?.length) {
      assignSingular();
    }
  }, [paymentInfoData, id, fundingDueData]);

  const handleAssignmentTypeChange = (value) => {
    if (value === "Assign Signular") {
      assignSingular();
    } else if (value === "Auto Apply (Full)") {
      handleAutoApplyFull();
    } else if (value === "Auto Apply (Cost)") {
      handleAutoApplyCost();
    } else if (value === "Auto Apply (Interest)") {
      handleApplyInterest();
    } else if (value === "Manual") {
      setManualAdvData({});
      setAssigmentType("Manual");
      setManualAdvData(() => createDocRecords(fundingDueData?.length));
    }
  };

  const totalPaymentApp = manualAdvData.reduce((sum, item) => {
    // Convert paymentApp to a number if it exists, otherwise default to 0
    const paymentValue = parseFloat(item.paymentApp) || 0;
    return sum + paymentValue;
  }, 0);

  const handleFundingTableNavigate = (e, value) => {
    if (e.target.tagName !== "INPUT" && e.target.tagName !== "SELECT") {
      navigate(`/dashboard/funding/view/${value?._id}`);
    }
  };
  // console.log("manualAdvData", manualAdvData);
  // console.log("manualAdv", manualAdv);
  // console.log("AssigmentType", assignmentType);
  return (
    <>
      <div className="mb-4">
        <Breadcrumb
          labels={["Payments", "Payment Assignment"]}
          stepsToRemove={2}
        />
        <Heading className="HeadingStyle">Payment Assignment</Heading>
      </div>

      <div className={style.info_history_wrap}>
        <div className={style.tableWrap}>
          <table class="table">
            <thead>
              <tr>
                <th colSpan={2}>Payment Information</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Client name</td>
                <td className={style.value}>{paymentInfoData?.client_name}</td>
              </tr>
              <tr>
                <td className={style.label}>Obligor name</td>
                <td className={style.value}>
                  {paymentInfoData?.obligor_name
                    ? paymentInfoData?.obligor_name
                    : paymentInfoData?.client_name}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Payment Date</td>
                <td className={style.value}>
                  {moment.utc(paymentInfoData?.date).format("YYYY-MM-DD")}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Amount</td>
                <td className={style.value}>
                  {NumberFormatAndAddZero(paymentInfoData?.amount)}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Currency</td>
                <td className={style.value}>{paymentInfoData?.currency}</td>
              </tr>
              <tr>
                <td className={style.label}>Status</td>
                <td className={style.value}>{paymentInfoData?.status}</td>
              </tr>
              {/* <tr>
                <td className={style.label}>Participant ID</td>
                <td className={style.value}>--</td>
              </tr> */}
              <tr>
                <td className={style.label}>Reserve Amount</td>
                <td className={style.value}>
                  {assignmentType === "Manual" ? (
                    <>
                      {parseFloat(parseFloat(paymentInfoData?.amount)) -
                        parseFloat(totalPaymentApp)}
                    </>
                  ) : (
                    <>
                      {reservedAmount
                        ? NumberFormatAndAddZero(reservedAmount)
                        : 0}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Assignment Type</td>
                <td className={style.value}>
                  <PrimitiveDropdown
                    selected={assignmentType}
                    options={[
                      "Assign Signular",
                      "Auto Apply (Full)",
                      "Auto Apply (Cost)",
                      "Auto Apply (Interest)",
                      "Manual",
                    ]}
                    onSelect={(value) => {
                      setAssigmentType(value);
                      handleAssignmentTypeChange(value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td className={style.label}>Calculation Date</td>
                <td className={style.value}>
                  <div style={{ position: "relative", width: "100%" }}>
                    <DateSelector
                      placeholder="Calculation Date"
                      onChange={(date) => {
                        setSelectedCalcluationDate(date);
                      }}
                      selectedDate={selectedCalcluationDate}
                      // disabled
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className={style.history_wrap}>
          <div className={style.tableWrapRight}>
            <ScrollableTable>
              <table className="w-100 border-collapse">
                <thead>
                  <tr className="bg-gray">
                    <th colSpan="8" className="border p-2 text-left">
                      Obligor Assets Due
                    </th>
                    <th
                      colSpan={
                        manualAdv ||
                        autoApplyInterest ||
                        autoApplyFull ||
                        autoApplyCost
                          ? "7"
                          : "6"
                      }
                      className="border p-2 text-left"
                    >
                      As of Calculation Date
                    </th>
                  </tr>
                  <tr className="bg-gray">
                    <th
                      className={`border py-2 px-3 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Info
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Obligor name
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Supplier name
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Due Date
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Client Ref
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Fund
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Status
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Currency
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Cost
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                    >
                      Face value
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap} ${style.wordBreak}`}
                    >
                      Principal Outstanding
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap} ${style.wordBreak}`}
                    >
                      Interest Accrued
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap} ${style.wordBreak}`}
                    >
                      Amount to Close
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap} ${style.wordBreak}`}
                    >
                      Payment Application
                    </th>
                    <th
                      className={`border p-2 text-left ${style.tabelRowStyleWrap} ${style.wordBreak}`}
                    >
                      Post Application Status
                    </th>
                    {assignmentType === "Manual" && (
                      <th
                        className={`border p-2 text-left ${style.tabelRowStyleWrap}`}
                      >
                        Repayment Type
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {fundingDueData?.map((row, index) => {
                    const isPaymentOpen =
                      row?.paymentHistory?.[0]?.paymentState === "open";
                    const isEditable = manualAdv && isPaymentOpen;
                    return (
                      <>
                        <tr className={style.obligorduetable} key={index}>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              className="cursor-pointer"
                              onClick={(e) => {
                                handleFundingTableNavigate(e, row);
                              }}
                            >
                              <InfoIcon />
                            </div>
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            <div className="line-truncate">
                              {row.obligor_name
                                ? row.obligor_name
                                : row?.client_name}
                            </div>
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            <div className="line-truncate">
                              {row?.supplier_name
                                ? row?.supplier_name
                                : row?.client_name}
                            </div>
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {moment.utc(row?.due_date).format("YYYY-MM-DD")}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {row?.transaction_type === "Obligor" && (
                              <>{breakStringInMiddle(row?.invoice_number)}</>
                            )}
                            {(row?.transaction_type === "Supplier Credit-1" ||
                              row?.transaction_type === "Supplier Credit" ||
                              row?.transaction_type === "Supplier") && (
                              <>
                                <span className="line-truncate">
                                  <>
                                    {breakStringInMiddle(row?.invoice_number)}
                                  </>
                                </span>
                              </>
                            )}
                            {(row?.transaction_type === "Client" ||
                              row?.transaction_type === "Note") && (
                              <>
                                {/* {console.log(
                                  "--->",
                                  breakStringInMiddle(row?.referance),
                                )} */}
                                <span className="line-truncate">
                                  {breakStringInMiddle(row?.referance)}
                                </span>
                              </>
                            )}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {row?.funder?.toUpperCase()}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {row?.status === "Funded" ? "Open" : row?.status}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {row?.currency}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {NumberFormatAndAddZero(row?.cost_value)}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {NumberFormatAndAddZero(
                              parseFloat(row?.face_value),
                            )}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {NumberFormatAndAddZero(row?.cost_value)}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {NumberFormatAndAddZero(row?.dailyInterest)}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {NumberFormatAndAddZero(
                              parseFloat(row?.cost_value) +
                                parseFloat(row?.dailyInterest),
                            )}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {assignmentType === "Manual" ? (
                              <>
                                <CustomInput
                                  onblur={(data) => {
                                    // console.log("data", data);
                                    const tempData = [...manualAdvData];
                                    tempData[index] = {
                                      ...tempData[index],
                                      ...row,
                                      paymentApp: data,
                                    };
                                    setManualAdvData(tempData);
                                  }}
                                  onchange={(data) => {
                                    const tempData = [...manualAdvData];
                                    tempData[index] = {
                                      ...tempData[index],
                                      ...row,
                                      paymentApp: data,
                                    };
                                    setManualAdvData(tempData);
                                  }}
                                  value={manualAdvData[index].paymentApp}
                                  inputType="text"
                                />
                              </>
                            ) : (
                              <>
                                {manualAdv ||
                                autoApplyInterest ||
                                autoApplyFull ||
                                autoApplyCost ? (
                                  <CustomInput
                                    onchange={(data) => {
                                      const tempData = [...manualAdvData];
                                      tempData[index].paymentApp = data;
                                      setManualAdvData(tempData);
                                    }}
                                    onblur={() => {}}
                                    value={manualAdvData[index].paymentApp}
                                    inputType="text"
                                    key={manualAdvData[index].paymentApp}
                                  />
                                ) : row?.paymentHistory?.[0]?.paymentAmount ? (
                                  row?.paymentHistory?.[0]?.paymentAmount
                                ) : (
                                  0
                                )}
                              </>
                            )}
                          </td>
                          <td
                            className={`border p-2 ${style.tabelRowStyleWrap}`}
                          >
                            {assignmentType === "Manual" ? (
                              <>
                                <CustomDropDown
                                  dropDownItem={[
                                    { label: "Open", value: "Open" },
                                    { label: "Close", value: "Close" },
                                  ]}
                                  onSelect={(value) => {
                                    const tempData = [...manualAdvData];
                                    tempData[index] = {
                                      ...tempData[index],
                                      ...row,
                                      postAppStatus: value,
                                    };
                                    setManualAdvData(tempData);
                                  }}
                                  selectedValue={
                                    manualAdvData[index]?.postAppStatus
                                  }
                                  key={manualAdvData[index]?.postAppStatus}
                                />
                              </>
                            ) : (
                              <>
                                {manualAdv ||
                                autoApplyInterest ||
                                autoApplyFull ||
                                autoApplyCost ? (
                                  <CustomDropDown
                                    dropDownItem={[
                                      { label: "Open", value: "Open" },
                                      { label: "Close", value: "Close" },
                                    ]}
                                    onSelect={(value) => {
                                      const tempData = [...manualAdvData];
                                      tempData[index].postAppStatus = value;
                                      setManualAdvData(tempData);
                                    }}
                                    selectedValue={
                                      manualAdvData[index]?.postAppStatus
                                    }
                                  />
                                ) : row?.paymentHistory?.[0]?.paymentState ? (
                                  row?.paymentHistory?.[0]?.paymentState
                                ) : (
                                  "open"
                                )}
                              </>
                            )}
                          </td>
                          {assignmentType === "Manual" && (
                            <td
                              className={`border p-2 ${style.tabelRowStyleWrap}`}
                            >
                              <CustomDropDown
                                dropDownItem={[
                                  { label: "N/A", value: "N/A" },
                                  { label: "Interest", value: "Interest" },
                                  { label: "Principle", value: "Principle" },
                                  {
                                    label: "PIK-Interest",
                                    value: "PIK-Interest",
                                  },
                                ]}
                                onSelect={(value) => {
                                  const tempData = [...manualAdvData];
                                  tempData[index] = {
                                    ...tempData[index],
                                    ...row,
                                    paymentType: value,
                                  };
                                  setManualAdvData(tempData);
                                }}
                                selectedValue={
                                  manualAdvData[index]?.paymentType
                                }
                              />
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </ScrollableTable>
          </div>
        </div>
      </div>

      <div className="mt-auto">
        <div className={style.CustomButtonWrap}>
          <div className={style.autoWrap}>
            {/* <CustomButton
              text="Confirm and Apply"
              size="btn-lg"
              type="btn-primary"
              handleClick={() => {
                handleConfirmAndApply();
              }}
            /> */}
            <button
              className="btn-primary btn-lg"
              type="submit"
              style={{ width: "100%", padding: "10px" }}
              onClick={() => {
                handleConfirmAndApply();
              }}
            >
              Confirm and Apply
              {isLoadind && (
                <span
                  className="spinner-border spinner-border-sm ms-2 text-light"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
          <div>
            <CustomButton
              text="Back"
              size="btn-lg"
              type="btn-outline-dark"
              handleClick={() => {
                navigate("/dashboard/payments");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ReviewView;
