import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../../atoms/Heading";
import IconCircle from "../../../atoms/IconCircle";
import Counterparty from "../../../icons/Counterparty";
import Funding from "../../../icons/Funding";
import Notes from "../../../icons/Notes";
import Payments from "../../../icons/Payments";
import Reports from "../../../icons/reports";
import Settings from "../../../icons/setting";
import InfoCard from "../../../molecules/DashboardMolecules/InfoCard";
import Charts from "./Charts";
import "./styles.scss";
import ClientSummary from "./Tables/ClientSummary";
import FundingSummary from "./Tables/FundingSummary";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { getCurrencyRate } from "../../../../services/currencyConvertor";
import { getData } from "../../../../services/index";
import CustomDateRangePicker from "../../../atoms/DateRangePicker";
import Interest from "../../../icons/Interest";
import { convertAndAddCurrencies } from "./helper/currency";

const Dashboard = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies(["t", "uid"]);
  const [widgetData, setWidgetData] = useState();
  const [currencyConversion, setCurrencyConversion] = useState(null);
  const [date, setDate] = useState([null, null]);
  const isSmallScreen = useMediaQuery("(max-width:1550px)");
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Dashboard;

  const getWidgetData = async () => {
    const res = await getData({
      endpoint: "dashboard/getWidgets",
      token: cookies.t,
      params: {
        start_date: date?.start_date?.replace(".000", ""),
        end_date: date?.end_date?.replace(".000", ""),
      },
    });

    if (res?.data) {
      setWidgetData(res?.data);
    }
  };

  useEffect(() => {
    const fetchCurrencyRate = async () => {
      try {
        const res = await getCurrencyRate({ date: new Date() });
        setCurrencyConversion(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };

    fetchCurrencyRate(); // Call the async function
  }, []);

  useEffect(() => {
    // Call the synchronous function (or async if needed)
    getWidgetData();
  }, [date]);
  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Heading className="HeadingStyle"> Dashboard</Heading>
        {roleData?.tabs && <CustomDateRangePicker setDate={setDate} />}
      </div>
      <div className="dashboard-container">
        <div className="d-flex gap-3 align-items-start justify-content-between">
          {roleData?.tabs && (
            <>
              <div className="widgets">
                <InfoCard
                  data={widgetData?.total_deals}
                  title="Deals Funded"
                  icon={<Payments />}
                  color={"#efefef"}
                  iconSize={"20px"}
                />
                <InfoCard
                  data={convertAndAddCurrencies(
                    widgetData?.totalFundedData,
                    currencyConversion,
                    "million",
                  )}
                  title="Amount Funded"
                  icon={<Funding />}
                  color={"#efefef"}
                  iconSize={"20px"}
                />
                <InfoCard
                  data={widgetData?.total_open_clients}
                  title="Current Clients"
                  icon={<Counterparty />}
                  color={"#efefef"}
                  iconSize={"20px"}
                />
                <InfoCard
                  data={convertAndAddCurrencies(
                    widgetData?.totalInvestedData,
                    currencyConversion,
                    "million",
                  )}
                  title="Invested Amount"
                  icon={<Payments />}
                  color={"#efefef"}
                  iconSize={"20px"}
                />
                <InfoCard
                  data={convertAndAddCurrencies(
                    widgetData?.totalInterestData,
                    currencyConversion,
                    "million",
                  )}
                  title="Interest Accrued"
                  icon={<Interest />}
                  color={"#efefef"}
                  iconSize={"20px"}
                />
              </div>
              <div
                className="common"
                style={{
                  width: isSmallScreen ? "55%" : "25%",
                  padding: "1rem 0",
                }}
              >
                <Heading
                  className={"HeadingSubStyle d-flex justify-content-center"}
                >
                  Quick Links
                </Heading>
                <div className="quick-links">
                  <div
                    className="quick-link"
                    onClick={() => navigate("funding/add")}
                  >
                    <IconCircle
                      icon={<Funding />}
                      color={"#efefef"}
                      iconSize={"14px"}
                      size="45px"
                    />
                    <div className="description">New Funding Request</div>
                  </div>

                  <div
                    className="quick-link"
                    onClick={() => navigate("reports/portfolio")}
                  >
                    <IconCircle
                      icon={<Reports />}
                      color={"#efefef"}
                      iconSize={"14px"}
                      size="45px"
                    />
                    <div className="description">Portfolio</div>
                  </div>
                  <div className="quick-link" onClick={() => navigate("notes")}>
                    <IconCircle
                      icon={<Notes />}
                      color={"#efefef"}
                      iconSize={"14px"}
                      size="45px"
                    />
                    <div className="description">Notes</div>
                  </div>

                  <div
                    className="quick-link"
                    onClick={() => navigate("setting/rate-management")}
                  >
                    <IconCircle
                      icon={<Settings />}
                      color={"#efefef"}
                      iconSize={"14px"}
                      size="45px"
                    />
                    <div className="description">Rates</div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="main">
          <div className="left">
            <div className="common">
              <Heading className={"HeadingSubStyle"}>Funding Summary</Heading>

              <div className="mt-2">
                <FundingSummary date={date} />
              </div>
            </div>
            <div className="common">
              <Heading className={"HeadingSubStyle"}>Client Summary</Heading>
              <div className="mt-2">
                <ClientSummary
                  date={date}
                  currencyConversion={currencyConversion}
                />
              </div>
            </div>
          </div>
          {/* <div className="right">
            <div className="common">
              <Heading className={"HeadingSubStyle"}>Quick Links</Heading>
              <div className="quick-links">
                <div
                  className="quick-link"
                  onClick={() => navigate("funding/add")}
                >
                  <IconCircle
                    icon={<Funding />}
                    color={"#efefef"}
                    iconSize={"14px"}
                    size="45px"
                  />
                  <div className="description">New Funding Request</div>
                </div>

                <div
                  className="quick-link"
                  onClick={() => navigate("reports/portfolio")}
                >
                  <IconCircle
                    icon={<Reports />}
                    color={"#efefef"}
                    iconSize={"14px"}
                    size="45px"
                  />
                  <div className="description">Portfolio</div>
                </div>
                <div className="quick-link" onClick={() => navigate("notes")}>
                  <IconCircle
                    icon={<Notes />}
                    color={"#efefef"}
                    iconSize={"14px"}
                    size="45px"
                  />
                  <div className="description">Notes</div>
                </div>

                <div
                  className="quick-link"
                  onClick={() => navigate("setting/rate-management")}
                >
                  <IconCircle
                    icon={<Settings />}
                    color={"#efefef"}
                  iconSize={"14px"}
                    size="45px"
                  />
                  <div className="description">Rates</div>
                </div>
              </div>
            </div>
            <div className="common">
              <Charts
                conversionRate={currencyConversion}
                title={"Exposure"}
                type={"exposure"}
                date={date}
              />
            </div>
            <div className="common">
              <Charts
                conversionRate={currencyConversion}
                title={"Funds Exposure"}
                type={"investment"}
                date={date}
              />
            </div>
          </div> */}
        </div>
        <div className="right">
          {roleData?.tabs && (
            <div className="common mb-4 mt-4">
              <Charts
                conversionRate={currencyConversion}
                title={"Exposure"}
                type={"exposure"}
                date={date}
              />
            </div>
          )}
          {roleData?.tabs && (
            <div className="common">
              <Charts
                conversionRate={currencyConversion}
                title={"Funds Exposure"}
                type={"investment"}
                date={date}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default Dashboard;
