import moment from "moment";
import React, { useEffect, useState } from "react";
import { NumberFormatAndAddZero } from "../../../../../../helpers/capatalizeLetters"; // Replace with your actual utility import
import { getCurrencyRate } from "../../../../../../services/currencyConvertor";
import ViewMore from "../../../../../atoms/ViewMore"; // Adjust the path as necessary
import style from "./index.module.scss"; // Replace with your actual stylesheet import

const renderRow = (label, value) => (
  <tr>
    <td style={{ width: "35%" }} className={style.label}>
      {label}
    </td>
    <td className={style.value}>{value || "--"}</td>
  </tr>
);

const renderTableWithColumns = (header, columns, rows) => (
  <table className="table mb-0">
    {/* Table Header */}
    <thead>
      {header && (
        <tr>
          <th className={style.top_header} colSpan={columns.length}>
            {header}
          </th>
        </tr>
      )}
      <tr>
        {columns?.map((col, index) => (
          <th key={index} className={style.value} style={{ width: "50%" }}>
            {col}
          </th>
        ))}
      </tr>
    </thead>
    {/* Table Body */}
    <tbody>
      {rows?.map((row, index) => (
        <tr key={index}>
          {row?.map((cell, cellIndex) => (
            <td key={cellIndex} className={style.label}>
              {cell || "--"}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

const renderTable = (header, rows) => (
  <table className="table">
    <thead>
      <tr>
        <th className={style.top_header} colSpan="2">
          {header}
        </th>
      </tr>
    </thead>
    <tbody>{rows}</tbody>
  </table>
);

export const CounterPartyInternalInfo = ({ counterData }) => {
  const internalInfoRows = [
    renderRow(
      "Agreement Date",
      counterData.agreement_date
        ? moment.utc(counterData.agreement_date).format("YYYY-MM-DD")
        : "--",
    ),
    renderRow(
      "Financing Entity",
      counterData.agreement_counter ? counterData.agreement_counter : "--",
    ),
    renderRow("Template Funding Reference", "--"),
    renderRow("Currency", counterData.primary_currency),
    renderRow("S&P Number", counterData.sp_number),
    renderRow("D&B Number", counterData.db_number),
  ];
  return <div>{renderTable("Internal Information", internalInfoRows)}</div>;
};

export const RelatedPatiesInfo = ({ supplierObligordata, counterData }) => {
  const brokerRows = counterData?.brokerDetails?.map((item) => [
    item?.broker_name,
    item.fee_rate,
  ]);

  const signerRows = counterData?.signerDetails?.map((item) => [
    `${item?.first_name} ${item?.last_name}`,
    <a href={`mailto:${item.email}`}>{item.email}</a>,
  ]);
  const rows = [
    renderRow(
      "Obligors",
      supplierObligordata &&
        supplierObligordata.obligor &&
        supplierObligordata.obligor.length > 0
        ? supplierObligordata.obligor.map((obligor) => (
            // <span key={obligor.id}>
            <>
              <a
                href={`/dashboard/counterparty/view/${obligor.id}`} // Replace with your route structure
                // style={{
                //   // color: "#1B242C",
                //   textDecoration: "underline",
                //   cursor: "pointer",
                //   fontFamily : 400
                // }}
                key={obligor.id}
                target="_blank"
                rel="noreferrer"
              >
                {obligor.name}
              </a>
              <br />
            </>
          ))
        : "None",
    ),
    renderRow(
      "Suppliers",
      supplierObligordata &&
        supplierObligordata?.supplier &&
        supplierObligordata?.supplier.length > 0
        ? supplierObligordata?.supplier.map((supplier) => (
            // <span key={supplier.id}>
            <>
              <a
                href={`/dashboard/counterparty/view/${supplier.id}`} // Replace with your route structure
                // style={{
                //   color: "#1B242C",
                //   textDecoration: "underline",
                //   cursor: "pointer",
                // }}
                key={supplier.id}
                target="_blank"
                rel="noreferrer"
              >
                {supplier.name}
              </a>
              <br />
            </>
            // </span>
          ))
        : "None",
    ),
    renderRow(
      "Signers",
      renderTableWithColumns(null, ["Name", "Email"], signerRows),
    ),
    renderRow(
      "Brokers",
      renderTableWithColumns(null, ["Name", "Rate"], brokerRows),
    ),
  ];

  return <div>{renderTable("Related Parties Info", rows)}</div>;
};

export const PriceInformation = ({ priceInformation }) => {
  const rows = [
    renderRow("Rate Type", priceInformation?.rate_type),
    renderRow("Name", priceInformation?.name),
    renderRow("Days", priceInformation?.days),
    renderRow("Top Rate", priceInformation?.top_rate),
    renderRow("Description", priceInformation?.description),
  ];

  return <div>{renderTable("Price Information", rows)}</div>;
};

export const ClientReportingInformation = ({ clientData }) => {
  const rows = [
    renderRow(
      "Client Name",
      clientData?.counter_type === "Client"
        ? clientData?.counter_name
        : clientData?.client_name,
    ),
    renderRow("     Report Request Email(s)", "--"),
    renderRow("    Last Recived As Of Report Date", "--"),
  ];

  return <div>{renderTable("Client Reporting Information", rows)}</div>;
};

const CounterPartyBasicInfo = ({ clientData, type, creditInfo, duesData }) => {
  const [currencyConversion, setCurrencyConversion] = useState(null);

  useEffect(() => {
    const fetchCurrencyRate = async () => {
      try {
        const res = await getCurrencyRate({ date: new Date() });
        setCurrencyConversion(res);
      } catch (error) {
        console.error("Error fetching currency rate:", error);
      }
    };

    fetchCurrencyRate(); // Call the async function
  }, []);

  if (!clientData) {
    return <div>No client data available.</div>;
  }

  const counterpartyInfoRows = [
    clientData?.counter_type !== "Client" &&
      renderRow("Client Name:", clientData.client_name),
    renderRow(
      "Name:",
      clientData?.counter_type !== "Client"
        ? clientData?.counter_name
        : clientData?.client_name || clientData?.counter_name,
    ),
    renderRow("Address:", clientData?.address),
    renderRow("TaxID:", clientData?.tax_id),
    renderRow("Type:", clientData?.counter_type),
    renderRow(
      "Transaction Type:",
      clientData?.trans_type === "Factoring/AR"
        ? "Obligor"
        : clientData?.trans_type,
    ),
    renderRow("Verified:", clientData?.verified),
    renderRow(
      "Notes:",
      clientData?.notes ? <ViewMore data={clientData.notes} /> : null,
    ),
  ];

  const creditInfoRows = [
    renderRow("Counterparty Type:", clientData?.counter_type),
    renderRow(
      "Internal Credit Limit:",
      clientData?.credit_limit
        ? NumberFormatAndAddZero(clientData.credit_limit)
        : null,
    ),
    renderRow(
      "Credit Used:",
      clientData?.credit_limit
        ? NumberFormatAndAddZero(parseFloat(duesData))
        : null,
    ),
    renderRow(
      "Credit Remaining:",
      clientData?.credit_limit && duesData
        ? NumberFormatAndAddZero(
            parseFloat(clientData?.credit_limit) - parseFloat(duesData),
          )
        : clientData?.credit_limit,
    ),
    renderRow("Sector:", clientData?.gics_sector),
    renderRow("Industry:", clientData?.gics_industry),
    renderRow("Geography:", clientData?.geography),
    renderRow("Currency:", clientData?.primary_currency),
  ];

  const insuranceRows = clientData?.insuranceDetails?.map((data) => [
    `${NumberFormatAndAddZero(data.limit)}`, // Format limit with currency
    moment.utc(data.end_date).format("YYYY-MM-DD"), // Format end_date
    data.limit_type, // Type
  ]);

  return (
    <div className={style.fundedViewleftWrap}>
      {renderTable("Counterparty Information", counterpartyInfoRows)}
      {renderTable("Credit Information", creditInfoRows)}

      {type === "related" &&
        renderTableWithColumns(
          "Insurance Limit Information",
          ["Limit", "End Date", "Type"],
          insuranceRows,
        )}
    </div>
  );
};

export default CounterPartyBasicInfo;
