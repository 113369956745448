import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import FileViewer from "react-file-viewer";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { NumberFormatAndAddZero } from "../../../../../../helpers/capatalizeLetters";
import { extractFileName } from "../../../../../../helpers/extractFileName";
import { patchData } from "../../../../../../services";
import { getPandaApi } from "../../../../../../services/pandaDocs";
import Refresh from "../../../../../icons/Refresh";
import style from "../index.module.scss";

const BasicInfo = ({
  fundedDataById,
  pandaDocStatus,
  handlePandaDocsStatus,
  setFundedDataById,
}) => {
  const [fundingData, setFundingData] = useState();
  const [excelData, setExcelData] = useState(null);
  const [excelTypeData, setExcelTypeData] = useState({
    fileType: "",
    file: "",
  });
  const [cookies] = useCookies(["t", "roleGroup"]);
  const [currentIndex, setCurrentIndex] = useState({
    docIndex: 0,
    fileType: "",
    imagesType: "", // Add imagesType to the state
  });
  const [viewerKey, setViewerKey] = useState(0);

  useEffect(() => {
    if (fundedDataById?.documentsInvoice?.docInvoiceId) {
      handlePandaDocsStatus("initial");
    }
  }, [fundedDataById?.documentsInvoice?.docInvoiceId]);

  useEffect(() => {
    if (fundedDataById) {
      setFundingData(fundedDataById);
    }
    if (fundedDataById?.documents) {
      //   const groupedDocuments = fundedDataById?.documents.reduce((acc, doc) => {
      //     if (!acc[doc?.imagesType]) {
      //       acc[doc?.imagesType] = [];
      //     }
      //     acc[doc?.imagesType].push(doc);
      //     return acc;
      //   }, {});
      //   setDocumentsGroupItem(groupedDocuments);
      //   const firstImagesType = Object.keys(groupedDocuments)[0];
      //   if (firstImagesType) {
      //     setCurrentIndex({
      //       docIndex: 0, // Set to the first document
      //       fileType: groupedDocuments[firstImagesType][0]?.imagesType,
      //       imagesType: firstImagesType, // Set to the first imagesType
      //     });
      //   }
      setCurrentIndex({
        docIndex: 0, // Set to the first document
        fileType: fundedDataById?.documents?.[0]?.file,
      });
    }
  }, [fundedDataById]);

  const handleIndexFile = (index, doc) => {
    console.log("clicked data", index, doc);
    setCurrentIndex(() => ({
      docIndex: index,
      fileType: doc?.file,
    }));
    setViewerKey(index);
    const type = getFileType(doc?.file);
    if (type.toLowerCase() === "xls" || type.toLowerCase() === "xlsx") {
      setExcelTypeData(() => ({
        fileType: type,
        file: doc?.file,
      }));
    }
  };

  const xlsxFileDownload = async (fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const arrayBuffer = await response.arrayBuffer();
      const workbook = XLSX.read(arrayBuffer, { type: "array" });

      const sheetName = workbook.SheetNames[0];
      const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });
      setExcelData(sheetData);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (excelTypeData?.file) {
      xlsxFileDownload(excelTypeData.file);
    }
  }, [excelTypeData]);

  useEffect(() => {
    if (fundingData?.documents?.[0]?.file) {
      const fileTypeAtIndex0 = getFileType(fundingData.documents[0]?.file);
      if (fileTypeAtIndex0 === "xls" || fileTypeAtIndex0 === "xlsx") {
        setExcelTypeData(() => ({
          fileType: fileTypeAtIndex0,
          file: fundingData.documents[0]?.file,
        }));
      }
    }
  }, [fundingData?.documents, currentIndex]); // Run this effect when fundingData changes

  const getFileType = (url) => {
    const extension = url?.split(".")?.pop();
    return extension?.toLowerCase();
  };

  const handlePandaDocsStatus2 = async (data) => {
    const response = await getPandaApi({
      endpoint: `https://api.pandadoc.com/public/v1/documents/${fundedDataById?.documentsInvoice?.docInvoiceId}`,
      params: {},
      token: process.env.REACT_APP_PANDADOC_ID,
    });

    return response;
  };

  const handleUpdateDocStatus = async () => {
    const documentStatus = await handlePandaDocsStatus2();

    if (!documentStatus?.status) {
      console.error("Invalid data: Missing Status");
      return;
    }

    try {
      const res = await patchData({
        endpoint: "/funding/UpdateFundingDocStatus",
        token: cookies.t,
        data: { documentStatus: documentStatus.status },
        params: { id: fundedDataById._id },
      });

      if (res) {
        setFundedDataById({
          ...fundedDataById,
          documentStatus: res?.data?.documentStatus,
        });
        toast.success("Document Status Refreshed", {
          duration: 1000,
          style: confimationStyles,
        });
      } else {
        console.error("Failed to update document: No response data");
      }
    } catch (error) {
      console.error("Error updating document:", error);
    } finally {
      // setLoader(false);
    }
  };

  // {Object?.keys(documentsGroupItem)?.map((imagesType, index) => (
  //   <div key={index}>
  //     {/* <h3>{imagesType}</h3> */}
  //     <div className={style.documentsButtonWrap}>
  //       {documentsGroupItem?.[imagesType]?.map((doc, idx) => (
  //         <button
  //           className={"btn-primary btn-sm"}
  //           style={{}}
  //           onClick={() => {
  //             handleIndexFile(idx, doc, imagesType);
  //             setExcelData();
  //           }}
  //         >
  //           {index + 1}
  //         </button>
  //       ))}
  //     </div>
  //   </div>
  // ))}

  const handleDownloadFileURL = (file) => {
    const link = document.createElement("a");
    link.href = file;
    link.download = file.name || "download";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrap}>
        <table class="table">
          <thead>
            <tr>
              <th scope="col" colSpan={2}>
                Asset Information
              </th>
              {/* <th scope="col"></th> */}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={style.label}>Transaction Type</td>
              <td className={style.value}>
                {fundedDataById?.transaction_type === "Obligor"
                  ? "FA"
                  : fundedDataById?.transaction_type}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Client Invoice ID/Ref</td>
              <td className={style.value}>
                <div className="word-break-global">
                  {
                    fundedDataById?.invoice_number || fundedDataById?.referance
                    // ?.split(",")
                    // ?.map((part) => part.trim())
                    // .join(", ")
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td className={style.label}>Client Name</td>
              <td className={style.value}>{fundedDataById?.client_name}</td>
            </tr>
            {
              <tr>
                <td className={style.label}>Obligor Name</td>
                <td className={style.value}>
                  {fundedDataById?.obligor_name
                    ? fundedDataById?.obligor_name
                    : fundedDataById?.client_name}
                </td>
              </tr>
            }
            {
              <tr>
                <td className={style.label}>Supplier Name</td>
                <td className={style.value}>
                  {fundedDataById?.supplier_name
                    ? fundedDataById?.supplier_name
                    : fundedDataById?.client_name}
                </td>
              </tr>
            }
            <tr>
              <td className={style.label}>Term</td>
              <td className={style.value}>{fundedDataById?.payment_terms}</td>
            </tr>
            {fundedDataById?.req_advance_value && (
              <tr>
                <td className={style.label}>Requested Advance Value</td>
                <td className={style.value}>
                  {NumberFormatAndAddZero(fundedDataById?.req_advance_value)}
                </td>
              </tr>
            )}
            <tr>
              <td className={style.label}>Cost</td>
              <td className={style.value}>
                {NumberFormatAndAddZero(parseFloat(fundedDataById?.cost_value))}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Face Value</td>
              <td className={style.value}>
                {NumberFormatAndAddZero(parseFloat(fundedDataById?.face_value))}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Currency</td>
              <td className={style.value}>{fundedDataById?.currency}</td>
            </tr>
            <tr>
              <td className={style.label}>Invoice Date</td>
              <td className={style.value}>
                {" "}
                {fundedDataById?.invoice_date
                  ? moment
                      .utc(fundedDataById?.invoice_date)
                      .format("YYYY-MM-DD")
                  : ""}
              </td>
            </tr>
            {fundedDataById?.due_date && (
              <tr>
                <td className={style.label}>Due Date</td>
                <td className={style.value}>
                  {moment.utc(fundedDataById?.due_date).format("YYYY-MM-DD")}
                </td>
              </tr>
            )}
            <tr>
              <td className={style.label}>Funding Date</td>
              <td className={style.value}>
                {moment.utc(fundedDataById?.funded_date).format("YYYY-MM-DD")}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Participant</td>
              <td className={style.value}>
                {fundedDataById?.funder ? fundedDataById?.funder : "--"}
              </td>
            </tr>
            <tr>
              <td className={style.label}>Status</td>
              <td className={style.value}>{fundedDataById?.status}</td>
            </tr>
            <tr>
              <td className={style.label}>
                Schedule Status
                {/* <CustomButton
                  text="Refresh"
                  type="btn-primary"
                  size="btn-sm"
                  style={{ marginLeft: "10px" }}
                  handleClick={() => {
                    handlePandaDocsStatus();
                  }}
                /> */}
              </td>
              <td className={style.value}>
                <div className="d-flex align-items-center gap-3">
                  {/* {pandaDocStatus?.status ? pandaDocStatus?.status : ""} */}
                  {fundedDataById?.documentStatus
                    ? fundedDataById?.documentStatus
                    : ""}

                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      // handlePandaDocsStatus();
                      handleUpdateDocStatus();
                    }}
                  >
                    <Refresh size="17" />
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={style.fundedViewRightWrap}>
        <div className={style.iframeStyle}>
          <div className={style.documentsWrap}>
            {fundedDataById?.documents?.map((item, index) => {
              return (
                <>
                  <button
                    className={`${
                      currentIndex?.docIndex === index
                        ? "btn-primary"
                        : "btn-outline-dark"
                    } btn-sm ${style.button_file}`}
                    style={{
                      position: "relative",
                      height: "36px",
                      minWidth: "35px",
                      padding: "8px 0",
                    }}
                    onClick={() => {
                      handleIndexFile(index, item);
                      setExcelData();
                    }}
                  >
                    {index + 1}
                  </button>
                </>
              );
            })}
          </div>

          {currentIndex.fileType && (
            <>
              <div className="mt-2 d-flex justify-content-between align-items-center">
                <div>
                  <strong>Selected File:</strong>{" "}
                  {extractFileName(currentIndex.fileType)}
                </div>
                <button
                  className="btn-primary p-2"
                  onClick={() => handleDownloadFileURL(currentIndex?.fileType)}
                  style={{ borderRadius: "4px" }}
                >
                  Download
                </button>
              </div>
            </>
          )}

          <div className={`${style.border} mt-2`}>
            {currentIndex?.fileType && (
              <>
                {getFileType(currentIndex?.fileType) === "docx" && (
                  <FileViewer
                    fileType="docx"
                    filePath={currentIndex?.fileType}
                    pluginRenderers={DocViewerRenderers}
                  />
                )}
                {(getFileType(currentIndex?.fileType) === "png" ||
                  getFileType(currentIndex?.fileType) === "jpeg") && (
                  <img
                    src={currentIndex?.fileType}
                    alt="images"
                    width={"100%"}
                    height={"auto"}
                  />
                )}

                {getFileType(currentIndex?.fileType) === "pdf" && (
                  <>
                    <FileViewer
                      key={viewerKey}
                      fileType="pdf"
                      filePath={currentIndex?.fileType}
                      pluginRenderers={PDFRenderer}
                    />
                    {/* <DocViewer
                      key={viewerKey}
                      fileType="pdf"
                      documents={[currentIndex?.fileType]}
                      pluginRenderers={PDFRenderer}
                    /> */}
                  </>
                )}
                {excelData && (
                  <div className={style.excelBoadyWrap}>
                    <table border="1">
                      <thead>
                        <tr>
                          {excelData[0].map((header, i) => (
                            <th className={style.headerStyleCell} key={i}>
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {excelData.slice(1).map((row, i) => (
                          <tr key={i}>
                            {row.map((cell, j) => (
                              <td className={style.columnCellStyle} key={j}>
                                {cell}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  // <ExcelParser fileUrl={currentIndex?.fileType}/>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BasicInfo;
