import axios from "axios";
import moment from "moment";

export const getCurrencyRate = async () => {
  try {
    const res = await axios.get(
      `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@${moment().format(
        "YYYY-MM-DD",
      )}/v1/currencies/usd.json`,
    );

    let extracted;
    if (res) {
      const formatted = res?.data?.usd;

      const { gbp, eur } = formatted;
      extracted = { gbp, eur };
    }

    return extracted;
  } catch (err) {
    console.log("an Error Occureed");
  }
};
export const getCurrencyRateEuro = async () => {
  try {
    const res = await axios.get(
      `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@${moment().format(
        "YYYY-MM-DD",
      )}/v1/currencies/eur.json`,
    );

    let extracted;
    if (res) {
      const formatted = res?.data?.eur;

      const { gbp, usd } = formatted;
      extracted = { gbp, usd };
    }

    return extracted;
  } catch (err) {
    console.log("an Error Occureed");
  }
};

export const getCurrencyRateGBP = async () => {
  try {
    const res = await axios.get(
      `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@${moment().format(
        "YYYY-MM-DD",
      )}/v1/currencies/gbp.json`,
    );

    let extracted;
    if (res) {
      const formatted = res?.data?.gbp;

      const { eur, usd } = formatted;
      extracted = { eur, usd };
    }

    return extracted;
  } catch (err) {
    console.log("an Error Occureed");
  }
};
