// Sidebar.js
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/images/brand/logo.png";
import useScreenWidth from "../../../hooks/useScreenwidth";
import CircleAvatar from "../../atoms/CircleAvatar";
import LogoutModal from "../../atoms/modals/LogoutModal";
import Bottomarrow from "../../icons/Bottomarrow";
import Broker from "../../icons/Broker";
import Counterparty from "../../icons/Counterparty";
import Dashboard from "../../icons/dashboard";
import Funding from "../../icons/Funding";
import Insurance from "../../icons/Insurance";
import Investor from "../../icons/Investor";
import LeftArrow from "../../icons/LeftArrow";
import Notes from "../../icons/Notes";
import Payments from "../../icons/Payments";
import Setting from "../../icons/setting";
import Signer from "../../icons/Signer";
import Tools from "../../icons/Tools";
import CustomNavLink from "./CustomNavLink"; // Ensure the import
import "./sidebar.scss";

import { Menu, MenuItem } from "@mui/material";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { capitalizeLetter } from "../../../helpers/capatalizeLetters";
import { decodeCookieValue } from "../../../helpers/cookieHelper";
import Monitoring from "../../icons/monitoring";
import ReportsIcon from "../../icons/reports";
import UpwardArrow from "../../icons/UpwardArrow";

const Sidebar = ({ isActive, setIsActive, sidebarRef }) => {
  const screenWidth = useScreenWidth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cookies, setCookies] = useCookies("un");
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);

  const [expandedMenu, setExpandedMenu] = useState(null);

  const handleExpandMenu = (menuIndex) => {
    setExpandedMenu((prev) => (prev === menuIndex ? null : menuIndex)); // Toggle current menu or close it
  };

  const handleProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const links = [
    ...(accessData &&
    (accessData?.["Dashboard"]?.["view"] ||
      accessData?.["Dashboard"]?.["delete"] ||
      accessData?.["Dashboard"]?.["export"] ||
      accessData?.["Dashboard"]?.["add/edit"])
      ? [
          {
            to: "/dashboard",
            label: "Dashboard",
            icon: <Dashboard />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Funding"]?.["view"] ||
      accessData?.["Funding"]?.["delete"] ||
      accessData?.["Funding"]?.["export"] ||
      accessData?.["Funding"]?.["add/edit"] ||
      accessData?.["Funding"]?.["tabs"])
      ? [
          {
            to: "/dashboard/funding",
            label: "Funding",
            icon: <Funding />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    // ...(accessData && accessData?.["Funding"]
    //   ? [
    //       {
    //         to: "/dashboard/funds",
    //         label: "Funds",
    //         icon: <Funds />,
    //         alternatePath: "/dashboard/setting",
    //       },
    //     ]
    //   : []),
    ...(accessData &&
    (accessData?.["Payment"]?.["view"] ||
      accessData?.["Payment"]?.["delete"] ||
      accessData?.["Payment"]?.["export"] ||
      accessData?.["Payment"]?.["add/edit"])
      ? [
          {
            to: "/dashboard/payments",
            label: "Payments",
            icon: <Payments />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Notes"]?.["view"] ||
      accessData?.["Notes"]?.["delete"] ||
      accessData?.["Notes"]?.["export"] ||
      accessData?.["Notes"]?.["add/edit"])
      ? [
          {
            to: "/dashboard/notes",
            label: "Notes",
            icon: <Notes />,
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Tools"]?.["view"] ||
      accessData?.["Tools"]?.["delete"] ||
      accessData?.["Tools"]?.["export"] ||
      accessData?.["Tools"]?.["add/edit"])
      ? [
          {
            to: "/dashboard/tools",
            label: "Tools",
            icon: <Tools />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Counterparties"]?.["view"] ||
      accessData?.["Counterparties"]?.["delete"] ||
      accessData?.["Counterparties"]?.["export"] ||
      accessData?.["Counterparties"]?.["add/edit"])
      ? [
          {
            to: "/dashboard/counterparty",
            label: "Counterparties",
            icon: <Counterparty />,
            alternatePath: "/dashboard/setting",
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Reports"]?.["view"] ||
      accessData?.["Reports"]?.["export"] ||
      accessData?.["Reports"]?.["tabs"])
      ? [
          {
            // to: "/dashboard/profile-monitoring",
            label: "Reports",
            icon: <ReportsIcon />,
            alternatePath: "/dashboard/setting",
            subLinks: [
              ...(accessData && !accessData?.["Reports"]?.["tabs"]
                ? [
                    {
                      to: "/dashboard/reports/portfolio",
                      label: "Portfolio",
                    },
                    {
                      to: "/dashboard/reports/due",
                      label: "Due",
                    },
                  ]
                : [
                    {
                      to: "/dashboard/reports/portfolio",
                      label: "Portfolio",
                    },
                    {
                      to: "/dashboard/reports/accural_day",
                      label: "Accrual(Day)",
                    },
                    {
                      to: "/dashboard/reports/accural_daily",
                      label: "Accrual(Daily)",
                    },
                    {
                      to: "/dashboard/reports/acuural_exp",
                      label: "Accrual(Exp)",
                    },
                    {
                      to: "/dashboard/reports/accural_icy",
                      label: "Accrual(Icy)",
                    },
                    {
                      to: "/dashboard/reports/sector_geography",
                      label: "Sector/Geography",
                    },
                    {
                      to: "/dashboard/reports/due",
                      label: "Due",
                    },
                    {
                      to: "/dashboard/reports/insurance",
                      label: "Insurance",
                    },
                    {
                      to: "/dashboard/reports/brokers",
                      label: "Brokers",
                    },
                    {
                      to: "/dashboard/reports/rates",
                      label: "Rates",
                    },
                    {
                      to: "/dashboard/reports/reporting",
                      label: "Reporting",
                    },
                    {
                      to: "/dashboard/reports/covenants",
                      label: "Covenants",
                    },
                    {
                      to: "/dashboard/reports/ir",
                      label: "IR",
                    },
                  ]),
            ],
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Signers"]?.["view"] ||
      accessData?.["Signers"]?.["delete"] ||
      accessData?.["Signers"]?.["export"] ||
      accessData?.["Signers"]?.["add/edit"])
      ? [
          {
            to: "/dashboard/signer",
            label: "Signers",
            icon: <Signer />,
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Brokers"]?.["view"] ||
      accessData?.["Brokers"]?.["delete"] ||
      accessData?.["Brokers"]?.["export"] ||
      accessData?.["Brokers"]?.["add/edit"])
      ? [
          {
            to: "/dashboard/broker",
            label: "Brokers",
            icon: <Broker />,
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Investment"]?.["view"] ||
      accessData?.["Investment"]?.["delete"] ||
      accessData?.["Investment"]?.["export"] ||
      accessData?.["Investment"]?.["add/edit"])
      ? [
          {
            to: "/dashboard/investor",
            label: "Investment",
            icon: <Investor />,
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Insurance"]?.["view"] ||
      accessData?.["Insurance"]?.["delete"] ||
      accessData?.["Insurance"]?.["export"] ||
      accessData?.["Insurance"]?.["add/edit"])
      ? [
          {
            to: "/dashboard/insurance",
            label: "Insurance",
            icon: <Insurance />,
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Monitoring"]?.["view"] ||
      accessData?.["Monitoring"]?.["delete"] ||
      accessData?.["Monitoring"]?.["export"] ||
      accessData?.["Monitoring"]?.["add/edit"] ||
      accessData?.["Monitoring"]?.["tabs"])
      ? [
          {
            // to: "/dashboard/profile-monitoring",
            label: "Monitoring",
            icon: <Monitoring />,
            alternatePath: "/dashboard/setting",
            subLinks: [
              ...(accessData &&
              accessData?.["Monitoring"] &&
              (accessData?.["Monitoring"]?.["view"] ||
                accessData?.["Monitoring"]?.["delete"] ||
                accessData?.["Monitoring"]?.["export"]) &&
              accessData?.["Monitoring"]?.["tabs"]
                ? [
                    {
                      to: "/dashboard/monitoring/dashboard",
                      label: "Dashboard",
                    },
                  ]
                : []),
              {
                to: "/dashboard/monitoring/analysis",
                label: "Analysis",
              },
              ...(accessData &&
              accessData?.["Monitoring"] &&
              (accessData?.["Monitoring"]?.["view"] ||
                accessData?.["Monitoring"]?.["delete"] ||
                accessData?.["Monitoring"]?.["export"]) &&
              accessData?.["Monitoring"]?.["tabs"]
                ? [
                    {
                      to: "/dashboard/monitoring/valuation",
                      label: "Valuation",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...(accessData &&
    (accessData?.["Rates"]?.["view"] ||
      accessData?.["Rates"]?.["delete"] ||
      accessData?.["Rates"]?.["export"] ||
      accessData?.["Rates"]?.["add/edit"] ||
      accessData?.["Pricing"]?.["view"] ||
      accessData?.["Pricing"]?.["delete"] ||
      accessData?.["Pricing"]?.["export"] ||
      accessData?.["Pricing"]?.["add/edit"] ||
      accessData?.["Covenants"]?.["view"] ||
      accessData?.["Covenants"]?.["delete"] ||
      accessData?.["Covenants"]?.["export"] ||
      accessData?.["Covenants"]?.["add/edit"] ||
      accessData?.["Reporting Requirnments"]?.["view"] ||
      accessData?.["Reporting Requirnments"]?.["delete"] ||
      accessData?.["Reporting Requirnments"]?.["export"] ||
      accessData?.["Reporting Requirnments"]?.["add/edit"] ||
      accessData?.["User Management"]?.["view"] ||
      accessData?.["User Management"]?.["delete"] ||
      accessData?.["User Management"]?.["export"] ||
      accessData?.["User Management"]?.["add/edit"])
      ? [
          {
            label: "Configurations",
            icon: <Setting />,
            subLinks: [
              ...(accessData &&
              accessData?.["Rates"] &&
              (accessData?.["Rates"]?.["view"] ||
                accessData?.["Rates"]?.["delete"] ||
                accessData?.["Rates"]?.["export"] ||
                accessData?.["Rates"]?.["add/edit"])
                ? [
                    {
                      to: "/dashboard/setting/rate-management",
                      label: "Rates",
                    },
                  ]
                : []),
              ...(accessData &&
              (accessData?.["Pricing"]?.["view"] ||
                accessData?.["Pricing"]?.["delete"] ||
                accessData?.["Pricing"]?.["export"] ||
                accessData?.["Pricing"]?.["add/edit"])
                ? [
                    {
                      to: "/dashboard/setting/price",
                      label: "Pricing",
                    },
                  ]
                : []),
              ...(accessData &&
              (accessData?.["Covenants"]?.["view"] ||
                accessData?.["Covenants"]?.["delete"] ||
                accessData?.["Covenants"]?.["export"] ||
                accessData?.["Covenants"]?.["add/edit"])
                ? [
                    {
                      to: "/dashboard/setting/covenant",
                      label: "Covenants",
                    },
                  ]
                : []),
              ...(accessData &&
              (accessData?.["Reporting Requirements"]?.["view"] ||
                accessData?.["Reporting Requirements"]?.["delete"] ||
                accessData?.["Reporting Requirements"]?.["export"] ||
                accessData?.["Reporting Requirements"]?.["add/edit"])
                ? [
                    {
                      to: "/dashboard/setting/reporting-requirements",
                      label: "Reporting Requirements",
                    },
                  ]
                : []),
              ...(accessData &&
              (accessData?.["User Management"]?.["view"] ||
                accessData?.["User Management"]?.["delete"] ||
                accessData?.["User Management"]?.["export"] ||
                accessData?.["User Management"]?.["add/edit"])
                ? [
                    {
                      to: "/dashboard/setting/user-management",
                      label: "User Management",
                    },
                  ]
                : []),
            ],
          },
        ]
      : []),
  ];

  const memoizedSetIsActive = useCallback((newIsActive) => {
    setIsActive(newIsActive);
  }, []);

  useEffect(() => {
    memoizedSetIsActive(screenWidth >= 1233);
  }, [screenWidth, memoizedSetIsActive]);

  const handleSideBar = () => {
    // if (screenWidth < 1233) setIsActive(false);
  };

  const handleMouseEnter = () => {
    if (!isActive) {
      setIsActive(true);
    }
  };

  const handleMouseLeave = () => {
    if (isActive) {
      setIsActive(false);
    }
  };

  return (
    <div
      className={`sidebar ${!isActive ? "closed" : ""}`}
      ref={sidebarRef}
      // onMouseEnter={handleMouseEnter}
      // onMouseLeave={handleMouseLeave}
    >
      <div className="header">
        {isActive ? (
          <img
            src="https://supplyfistagecli.s3.us-east-1.amazonaws.com/static+icon/WHITE.png"
            alt="Logo"
            className="open-header-img"
            onClick={() => navigate("/dashboard")}
          />
        ) : (
          <img
            src={Logo}
            alt="Logo"
            className="closed-header-img"
            onClick={() => navigate("/dashboard")}
          />
        )}
        <div
          className={`drawer-div ${!isActive ? "closed" : ""}`}
          onClick={() => setIsActive((prev) => !prev)}
        >
          <LeftArrow />
        </div>
      </div>
      <ul className="ul">
        {links.map((link, index) => (
          <CustomNavLink
            key={index}
            to={link.to}
            label={link.label}
            icon={link.icon}
            handleSideBar={handleSideBar}
            isSideBarActive={isActive}
            setIsSideBarActive={setIsActive}
            isExpanded={expandedMenu === index} // Pass down whether this submenu is expanded
            onExpand={() => handleExpandMenu(index)} // Pass the handler for expanding this submenu
          >
            {link.subLinks?.map((subLink, subIndex) => (
              <CustomNavLink
                key={subIndex}
                to={subLink.to}
                label={subLink.label}
                handleSideBar={handleSideBar}
                isSideBarActive={isActive}
              />
            ))}
          </CustomNavLink>
        ))}
      </ul>
      <div className="profile" onClick={handleProfileMenu}>
        <div className="d-flex gap-2">
          <CircleAvatar
            color="#DEE3E7"
            iconColor="black"
            name={capitalizeLetter(decodeCookieValue(cookies.un))}
          />
          {isActive && (
            <span className="profile-span">
              {capitalizeLetter(decodeCookieValue(cookies.un))}
            </span>
          )}
        </div>

        {isActive && (anchorEl ? <UpwardArrow /> : <Bottomarrow />)}
      </div>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            minWidth: "200px",
            borderRadius: "6px",
            boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.15)",
            marginBottom: "40px",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "bottom",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "bottom",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/dashboard/edit-profile");
            setAnchorEl(null);
          }}
          style={{ fontWeight: 500 }}
        >
          <span>Edit Profile</span>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsModalOpen(!isModalOpen);
            setAnchorEl(null);
          }}
          style={{ fontWeight: 500 }}
        >
          <span style={{ color: "#BE0F00" }}>Logout</span>
        </MenuItem>
      </Menu>

      {isModalOpen && (
        <LogoutModal
          isActive={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Sidebar;
