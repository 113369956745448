import moment from "moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { NumberFormatAndAddZero } from "../../../../../helpers/capatalizeLetters";
import { getData } from "../../../../../services";
import NoContentCard from "../../../../atoms/NoContentCard";
import Pagination from "../../../../atoms/Pagination";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";

const FundingSummary = ({ date }) => {
  const [loader, setLoader] = useState(true);
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [portfolioData, setPortfolioData] = useState([]);

  const getFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundings",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          status: ["Funded", "Closed"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
          report_type: "Dashboard Portfolio",
          fundingStartDate: date?.start_date?.replace(".000", ""),
          fundingEndDate: date?.end_date?.replace(".000", ""),
        },
      });
      setPortfolioData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getFundingData();
  }, [itemsPerPage, currentPage, date]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        return (
          // <div className="d-flex flex-column">
          <div className="line-truncate">{params?.data?.client_name}</div>
          // </div>
        );
      },
      getCellClassName: () => "wrap-text",
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
      // sortable: true,
      cellRenderer: (params) => {
        return (
          // <div className="d-flex flex-column">
          <div className="line-truncate">
            {params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name}
          </div>
          // </div>
        );
      },
      getCellClassName: () => "wrap-text",
    },
    {
      field: "funded_date",
      headerName: "Funding Date",
      flex: 0.6,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <>
            <span>
              {moment.utc(params?.data?.funded_date).format("YYYY-MM-DD")}
            </span>
          </>
        );
      },
    },
    {
      field: "due_date",
      headerName: "Due Date",
      flex: 0.6,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <>
            <span>
              {params?.data?.due_date &&
                moment.utc(params?.data?.due_date).format("YYYY-MM-DD")}
            </span>
          </>
        );
      },
    },
    {
      field: "funder",
      headerName: "Participant",
      flex: 0.6,
      sortable: true,
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.5,
      sortable: true,
    },
    {
      field: "cost_value",
      headerName: "Cost",
      flex: 0.8,
      valueGetter: (params) => Number(params?.data?.cost_value) || 0,
      cellRenderer: (params) => {
        return (
          <>
            <span>
              {params?.data?.cost_value &&
                NumberFormatAndAddZero(params?.data?.cost_value)}
            </span>
          </>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      valueGetter: (params) => Number(params?.data?.face_value) || 0,
      cellRenderer: (params) => {
        return (
          <>
            <span>
              {params?.data?.face_value &&
                NumberFormatAndAddZero(params?.data?.face_value)}
            </span>
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title={"No Data"}
          // subtitle={content[type].subtitle}
          type="Counterparty"
          classes="d-flex align-items-center"
          height={"7rem"}
          fontSize={"20px"}
        />
      </>
    );
  });
  return (
    <div>
      <CustomDatagrid
        columns={columns}
        rows={portfolioData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="350px"
        loader={loader}
        onCellClick={(data) => {
          navigate(`/dashboard/funding/view/${data?.data?._id}`);
        }}
        height={70}
        smallTable
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={portfolioData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
        options={["5", "10", "15"]}
      />
    </div>
  );
};

export default FundingSummary;
