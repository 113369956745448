import React, { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { NumberFormatAndAddZero } from "../../../../helpers/capatalizeLetters";
import { getData } from "../../../../services";
import { downloadReport } from "../../../../services/downloadService";
import {
  CurrencyData,
  statusTypes,
  transactionTypeReports,
} from "../../../../utils/staticData";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import NoContentCard from "../../../atoms/NoContentCard";
import Pagination from "../../../atoms/Pagination";
import Search from "../../../atoms/Search";
import Upload from "../../../icons/Upload";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import style from "./index.module.scss";

const ReportsSector = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [portfolioData, setPortfolioData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const getFundingData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "funding/getAllFundingsBySector",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          currency: filters?.["Currency"],
          status: filters?.["Status"]
            ? filters?.["Status"]
            : ["Funded", "Draft", "Closed", "Reject"],
          transactionType: filters?.["Transaction Type"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setPortfolioData(res?.data);
    } catch (err) {
      console.log("errr", err);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getFundingData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  function getPopulateName(data) {
    switch (data.transaction_type) {
      case "Note":
      case "Revolver":
      case "Client":
        return data?.client_name;

      case "Factoring/AR":
      case "Obligor":
        return data?.obligor_name;

      case "Supplier Credit":
      case "Supplier Credit-1":
      case "Supplier":
        return data?.supplier_name;

      default:
        return "";
    }
  }

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.obligor_id
              ? params?.data?.client_name
              : params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">
            {params?.data?.client_id === params?.data?.supplier_id
              ? params?.data?.client_name
              : params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name
              ? params?.data?.client_name
              : ""}
          </div>
        );
      },
    },
    {
      field: "funder",
      headerName: "Funder",
      flex: 0.7,
    },
    // {
    //   field: "Geography",
    //   headerName: "Geography",
    //   flex: 1,
    //   cellRenderer: (params) => {
    //     return (
    //       <div
    //         // className={style.valueStyle}
    //         title={
    //           params?.data?.populatedInfo &&
    //           params?.data?.populatedInfo?.[0]?.geography
    //         }
    //       >
    //         {params?.data?.populatedInfo && (
    //           <span>{params?.data?.populatedInfo?.[0]?.geography}</span>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "Geography",
      headerName: "Geography",
      flex: 1,
      valueGetter: (params) => {
        return params?.data?.populatedInfo?.[0]?.geography || "";
      },
      cellRenderer: (params) => {
        return (
          <div className="line-truncate" title={params.value}>
            <span>{params.value}</span>
          </div>
        );
      },
    },

    // {
    //   field: "Industry",
    //   headerName: "Industry",
    //   flex: 1,
    //   cellRenderer: (params) => {
    //     return (
    //       <div
    //         className={"line-truncate"}
    //         title={
    //           params?.data?.populatedInfo &&
    //           params?.data?.populatedInfo?.[0]?.gics_industry
    //         }
    //       >
    //         {params?.data?.populatedInfo && (
    //           <span>{params?.data?.populatedInfo?.[0]?.gics_industry}</span>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "Industry",
      headerName: "Industry",
      flex: 1,
      valueGetter: (params) => {
        return params?.data?.populatedInfo?.[0]?.gics_industry || "";
      },
      cellRenderer: (params) => {
        return (
          <div className="line-truncate" title={params.value}>
            <span>{params.value}</span>
          </div>
        );
      },
    },
    // {
    //   field: "Sector",
    //   headerName: "Sector",
    //   flex: 1,
    //   cellRenderer: (params) => {
    //     return (
    //       <div
    //         className={"line-truncate"}
    //         title={
    //           params?.data?.populatedInfo &&
    //           params?.data?.populatedInfo?.[0]?.gics_sector
    //         }
    //       >
    //         {params?.data?.populatedInfo && (
    //           <span>{params?.data?.populatedInfo?.[0]?.gics_sector}</span>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "Sector",
      headerName: "Sector",
      flex: 1,
      valueGetter: (params) => {
        return params?.data?.populatedInfo?.[0]?.gics_sector || "";
      },
      cellRenderer: (params) => {
        return (
          <div className="line-truncate" title={params.value}>
            <span>{params.value}</span>
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 1,
      sortable: true,
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => Number(params?.data?.cost_value) || 0,
      valueFormatter: (params) =>
        params.value ? NumberFormatAndAddZero(params.value) : "0",
      cellRenderer: (params) => (
        <div className={style.valueStyle}>
          {NumberFormatAndAddZero(params.value)}
        </div>
      ),
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div
            title={
              params?.data?.transaction_type === "Obligor"
                ? "Factoring/AR"
                : params?.data?.transaction_type
            }
          >
            {params?.data?.transaction_type === "Obligor"
              ? "Factoring/AR"
              : params?.data?.transaction_type}
          </div>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      sortable: true,
      valueGetter: (params) => {
        return params.data?.status === "Funded" ? "Open" : params.data?.status;
      },
      cellRenderer: (params) => {
        return <span>{params.value}</span>;
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Sector/Geography created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filtersData = {
    "Transaction Type": transactionTypeReports,
    Currency: CurrencyData,
    Status: statusTypes,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "funding/exportSector",
      token: cookies.t,
      fileName: "Sector&Geography",
      params: {
        search: searchData,
        transactionType: filters?.["Transaction Type"],
        currency: filters?.["Currency"],
        status: filters?.["Status"]
          ? filters?.["Status"]
          : ["Funded", "Draft", "Closed", "Reject"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Sector & Geography Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Sector/Geography</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filtersData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>
      <CustomDatagrid
        rows={portfolioData?.[0]?.fundingData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        isRowsVisible
        isValueVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {/* {portfolioData?.[0]?.pageDetails?.count > 10 && ( */}
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={portfolioData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
      {/* )} */}
    </>
  );
};

export default ReportsSector;
