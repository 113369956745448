import moment from "moment/moment";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { convenantFrequency } from "../../../../helpers/staticData";
import { getData } from "../../../../services";
import { downloadReport } from "../../../../services/downloadService";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import NoContentCard from "../../../atoms/NoContentCard";
import Pagination from "../../../atoms/Pagination";
import Search from "../../../atoms/Search";
import Upload from "../../../icons/Upload";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import style from "./index.module.scss";

const ReportsReporting = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [covenantData, setCovenantData] = useState([]);
  const [clientData, setClientData] = useState(null);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const getCovenantData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "/reporting-requirements/getAll",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          frequency: filters?.["Frequency"],
          clients: filters?.["Client"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setCovenantData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getCovenantData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  useEffect(() => {
    (async () => {
      const res = await getData({
        endpoint: "reporting-requirements/getReqClientList",
        token: cookies.t,
      });
      if (res) {
        setClientData(res?.data);
      }
    })();
  }, []);

  const calculateNextReportDate = (clientData) => {
    let date;
    if (clientData?.frequency === "Monthly") {
      date = moment(clientData?.latest_selected_date)
        .add(1, "months")
        .format("YYYY-MM-DD");
    } else if (clientData?.frequency === "Quarterly") {
      date = moment(clientData?.latest_selected_date)
        .add(4, "months")
        .format("YYYY-MM-DD");
    } else if (clientData?.frequency === "Bi-annual") {
      date = moment(clientData?.latest_selected_date)
        .add(6, "months")
        .format("YYYY-MM-DD");
    } else if (clientData?.frequency === "Annual") {
      date = moment(clientData?.latest_selected_date)
        .add(12, "months")
        .format("YYYY-MM-DD");
    }
    return date;
  };

  const calculateNextDueDate = (clientData) => {
    let date;
    if (clientData?.frequency === "Monthly") {
      date = moment(clientData?.latest_selected_date)
        .add(1, "months")
        .format("YYYY-MM-DD");
    } else if (clientData?.frequency === "Quarterly") {
      date = moment(clientData?.latest_selected_date)
        .add(4, "months")
        .format("YYYY-MM-DD");
    } else if (clientData?.frequency === "Bi-annual") {
      date = moment(clientData?.latest_selected_date)
        .add(6, "months")
        .format("YYYY-MM-DD");
    } else if (clientData?.frequency === "Annual") {
      date = moment(clientData?.latest_selected_date)
        .add(12, "months")
        .format("YYYY-MM-DD");
    }
    return moment(date)
      .add(clientData?.reporting_days, "day")
      .format("YYYY-MM-DD");
  };

  const getDateStatus = (targetDate) => {
    if (!targetDate) return "";

    const target = moment(targetDate);
    const today = moment();
    const daysDifference = today.diff(target, "days");

    if (daysDifference > 0) {
      return `+${daysDifference} `;
    } else {
      return `-${Math.abs(daysDifference)}`;
    }
  };
  const handleReportRequirement = async () => {
    try {
      const res = await getData({
        endpoint: "reporting-requirements/sendEmails",
        token: cookies.t,
      });
      if (res) {
        toast.success("Reports Send Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const columns = [
    {
      field: "client_name",
      headerName: "Client Name",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "Requirenment",
      headerName: "Requirement",
      flex: 1,
      sortable: true,
      valueGetter: (param, row) => {
        return param?.data?.name;
      },
      cellRenderer: (params) => {
        return (
          <div className={`line-truncate`} title={params?.data?.name}>
            {params?.data?.name}
          </div>
        );
      },
    },
    {
      field: "Requirenment(Detail)",
      headerName: "Requirement(Detail)",
      flex: 1,
      sortable: true,
      valueGetter: (param, row) => {
        return param?.data?.long_description;
      },
      cellRenderer: (params) => {
        return (
          <div
            className={"line-truncate"}
            title={params?.data?.long_description}
          >
            {params?.data?.long_description}
          </div>
        );
      },
    },
    {
      field: "Frequency",
      headerName: "Frequency",
      flex: 0.9,
      sortable: true,
      valueGetter: (param, row) => {
        return param.data?.frequency;
      },
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.frequency}>{params?.data?.frequency}</div>
        );
      },
    },
    {
      field: "Shift",
      headerName: "Shift",
      flex: 0.5,
      type: "number",
      sortable: true,
      headerAlign: "left",
      align: "left",
      valueGetter: (param, row) => {
        return param?.data?.monthly_shift
          ? Number(param?.data?.monthly_shift)
          : null;
      },
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.monthly_shift}>
            {params?.data?.monthly_shift}
          </div>
        );
      },
    },
    {
      field: "Reporting Days",
      headerName: "Reporting Days",
      type: "number",
      sortable: true,
      flex: 1,
      headerAlign: "left",
      align: "left",
      valueGetter: (param, row) => {
        return param?.data?.reporting_days
          ? Number(param?.data?.reporting_days)
          : 0;
      },
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.reporting_days}>
            {params?.data?.reporting_days}
          </div>
        );
      },
    },
    {
      field: "Last Client Report Date(Overall)",
      headerName: "Last Client Report Date(Overall)",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <>
            <span className="">
              {moment(params?.data?.latest_selected_date).format("YYYY-MM-DD")}
            </span>
          </>
        );
      },
    },
    {
      field: "Next Report As of Date",
      headerName: "Next Report As of Date",
      flex: 1,
      sortable: true,
      valueGetter: (param, row) => {
        return param?.data?.next_reporting_date
          ? moment.utc(param?.data?.next_reporting_date).format("YYYY-MM-DD")
          : null;
      },
      cellRenderer: (params) => {
        const nextreportAsOfDate = calculateNextReportDate(params?.data);
        return (
          <span>{moment.utc(nextreportAsOfDate).format("YYYY-DD-MM")}</span>
        );
      },
    },
    {
      field: "Next Due Date",
      headerName: "Next Due Date",
      flex: 1,
      sortable: true,
      valueGetter: (param, row) => {
        return param?.data?.next_due_date
          ? moment.utc(param?.data?.next_due_date).format("YYYY-MM-DD")
          : null;
      },
      cellRenderer: (params) => {
        const nextreportdueDate = calculateNextDueDate(params?.data);
        return (
          <span>{moment.utc(nextreportdueDate).format("YYYY-DD-MM")}</span>
        );
      },
    },
    {
      field: "Days Due",
      headerName: "Days Due (+Past Due/-Till Due)",
      flex: 0.8,
      sortable: true,
      cellRenderer: (params) => {
        const nextDueDate = calculateNextReportDate(params?.data);
        const dateStatus = getDateStatus(nextDueDate);
        return (
          <>
            <span>{dateStatus}</span>
          </>
        );
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Reporting created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    Frequency: convenantFrequency,
    Client: clientData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "reporting-requirements/export",
      token: cookies.t,
      fileName: "Requirements",
      params: {
        search: searchData,
        frequency: filters?.["Frequency"],
        clients: filters?.["Client"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Reporting Requirements Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">
            Client Reporting Requirements
          </Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />
          <CustomButton
            text="Report"
            type="btn-primary"
            handleClick={() => {
              handleReportRequirement();
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filterData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={covenantData?.[0]?.data || []}
        getRowId={(row) => row?.unique_row_id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        isRowsVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={covenantData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

export default ReportsReporting;
