import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import {
  NumberFormatAndAddZero,
  Obj2FormData,
} from "../../../../../../helpers/capatalizeLetters";
import { findRateForMonth } from "../../../../../../helpers/finalDateCalculate";
import { getData, patchFormData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomButton";
import { Heading } from "../../../../../atoms/Heading";
import ImageSelector from "../../../../../atoms/ImageSelector/ImageSelector";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import Close from "../../../../../icons/Close";
import style from "../index.module.scss";

const AdditionalDocUpload = ({
  selectedImage,
  setSelectedImage,
  handleMultipleImages,
  handleRemoveImage,
  pricingData,
  fundedDataById,
}) => {
  const [cookies] = useCookies(["t"]);
  const [interestDate, setInterestData] = useState({
    startDate: null,
    endDate: null,
  });
  const [interestRateFloating, setInterestRateFloating] = useState();
  const [interestRateFlat, setInterestFlat] = useState();
  const [dailyInterest, setDailyInterest] = useState([]);
  const [runQuery, setRunQuery] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState();

  const getInterestRate = async (rateID) => {
    try {
      const res = await getData({
        endpoint: "rates/getRateById",
        token: cookies.t,
        params: { id: rateID },
      });
      setInterestRateFloating(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (pricingData?.rate_type === "Floating") {
      getInterestRate(pricingData?.base_rate);
    } else if (pricingData?.rate_type === "Flat") {
      setInterestFlat(pricingData?.top_rate);
    }
  }, [pricingData]);

  useEffect(() => {
    if (fundedDataById?.paymentHistory) {
      setPaymentDetails(fundedDataById?.paymentHistory);
    }
  }, [fundedDataById?.paymentHistory]);

  const convertToDDMMYYYY = (isoDate) => {
    const date = new Date(isoDate); // Convert ISO date string to Date object
    const day = String(date.getDate()).padStart(2, "0"); // Get the day and pad it to 2 digits
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Get the month (0-based, so +1) and pad it
    const year = date.getFullYear(); // Get the year

    return `${day}-${month}-${year}`; // Return the formatted date as DD-MM-YYYY
  };

  useEffect(() => {
    if (interestRateFloating?.rates && runQuery) {
      if (
        fundedDataById?.transaction_type === "Revolver" ||
        fundedDataById?.transaction_type === "Note"
      ) {
        const tempTotalInterest = findRateForMonth(
          interestRateFloating?.rates,
          fundedDataById?.funded_date,
        );
        const tempDailyInterest = calculateDailyCompoundInterest(
          parseFloat(fundedDataById?.cost_value),
          parseFloat(tempTotalInterest?.base_rate) +
            parseFloat(pricingData?.top_rate),
          moment.utc(fundedDataById?.funded_date).add(1, "day").toISOString(),
          interestDate.endDate,
          paymentDetails,
        );

        const filteredData = tempDailyInterest.filter((item) => {
          return (
            item.date >= interestDate.startDate &&
            item.date <= interestDate?.endDate
          );
        });
        setDailyInterest(tempTotalInterest);
      } else {
        const tempTotalInterest = findRateForMonth(
          interestRateFloating?.rates,
          new Date(fundedDataById?.funded_date),
        );
        const tempDailyInterest = calculateDailyCompoundInterest(
          parseFloat(fundedDataById?.cost_value),
          parseFloat(tempTotalInterest?.base_rate) +
            parseFloat(pricingData?.top_rate),
          moment.utc(fundedDataById?.funded_date).add(1, "day").toISOString(),
          interestDate.endDate,
          paymentDetails,
        );
        const filteredData = tempDailyInterest.filter((item) => {
          return (
            item.date >= interestDate.startDate &&
            item.date <= interestDate?.endDate
          );
        });
        setDailyInterest(tempDailyInterest);
      }
    } else if (interestRateFlat && runQuery) {
      if (
        fundedDataById?.transaction_type === "Revolver" ||
        fundedDataById?.transaction_type === "Note"
      ) {
        const tempDailyInterest = calculateDailyCompoundInterest(
          parseFloat(fundedDataById?.cost_value),
          pricingData?.top_rate,
          interestDate.startDate,
          interestDate.endDate,
        );
        const filteredData = tempDailyInterest.filter((item) => {
          return (
            item.date >= convertToDDMMYYYY(interestDate.startDate) &&
            item.date <= convertToDDMMYYYY(interestDate.endDate)
          );
        });
        setDailyInterest(filteredData);
      } else {
        const tempDailyInterest = calculateDailyCompoundInterest(
          parseFloat(fundedDataById?.cost_value),
          pricingData?.top_rate,
          moment(fundedDataById?.funded_date).add(1, "day").toISOString(),
          interestDate.endDate,
          paymentDetails,
        );
        setDailyInterest(tempDailyInterest);
      }
    }
  }, [interestRateFloating, runQuery, interestRateFlat]);

  const calculateDailyCompoundInterest = (
    principal,
    annualRate,
    startDate,
    endDate,
    paymentDetails,
  ) => {
    const start = new Date(
      Date.UTC(
        new Date(startDate).getUTCFullYear(),
        new Date(startDate).getUTCMonth(),
        new Date(startDate).getUTCDate(),
      ),
    );

    const end = new Date(
      Date.UTC(
        new Date(endDate).getUTCFullYear(),
        new Date(endDate).getUTCMonth(),
        new Date(endDate).getUTCDate(),
      ),
    );
    console.log("endDate (UTC):", end.toISOString());
    const dailyInterestRate = annualRate / 36000;

    let currentDate = new Date(start);
    const dailyInterest = principal * dailyInterestRate;
    let accumulatedInterest = dailyInterest;
    const results = [];

    while (currentDate <= end) {
      const formattedDate = currentDate.toISOString().split("T")[0];
      const result = {};
      result.date = formattedDate;

      let principalPaid = 0;
      let interestPaid = 0;

      const payment = paymentDetails?.find((p) => {
        const payDate = new Date(p?.paydate);
        return payDate.toISOString().split("T")[0] === formattedDate;
      });

      if (payment) {
        const paymentAmount = parseFloat(payment.paymentAmount);

        if (payment.paymentType === "Principle") {
          principalPaid = paymentAmount;
          principal -= principalPaid;
        } else if (payment.paymentType === "Interest") {
          interestPaid = paymentAmount;
          accumulatedInterest -= interestPaid;
        }
      }

      result.principal = principal?.toFixed(2);
      result.principalPaid = principalPaid?.toFixed(2);
      result.interestPaid = interestPaid?.toFixed(2);
      result[formattedDate] = accumulatedInterest?.toFixed(2);
      result["Amount"] = (
        parseFloat(principal) + parseFloat(result[formattedDate])
      )?.toFixed(2);
      results.push(result);

      accumulatedInterest += dailyInterest;
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return results;
  };

  // const calculateDailyCompoundInterest = (
  //   principal,
  //   annualRate,
  //   startDate,
  //   endDate,
  // ) => {
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);
  //   const dailyInterestRate = annualRate / 36000;

  //   let currentDate = new Date(start);
  //   const dailyInterest = principal * dailyInterestRate;
  //   let accumulatedInterest = dailyInterest;
  //   const results = [];

  //   while (currentDate <= end) {
  //     const formattedDate = currentDate.toLocaleDateString("en-GB");
  //     const result = {};
  //     result.date = formattedDate;
  //     result[formattedDate] = accumulatedInterest?.toFixed(2);
  //     result["Amount"] =
  //       parseFloat(principal) + parseFloat(result[formattedDate]);
  //     results.push(result);

  //     accumulatedInterest += dailyInterest;
  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }

  //   return results;
  // };

  const calculateDailyCompoundInterestFloating = ({
    principal,
    floatingRate,
    flatRate,
    startDate,
    endDate,
    paymentDetails,
  }) => {
    const start = moment(startDate); // Handles ISO format directly
    const end = moment(endDate); // Handles ISO format directly

    if (!start.isValid() || !end.isValid()) {
      console.error("Invalid startDate or endDate.");
      return [];
    }

    const dailyInterest = [];
    let cumulativeInterest = 0;

    for (
      let current = start.clone();
      current.isBefore(end) || current.isSame(end, "day");
      current.add(1, "days")
    ) {
      let applicableRate = null;

      // Find the floating rate applicable for the current day
      for (let i = 0; i < floatingRate.length; i++) {
        const rateStart = moment(floatingRate[i].date);
        const rateEnd =
          i + 1 < floatingRate.length ? moment(floatingRate[i + 1].date) : end;

        if (current.isBetween(rateStart, rateEnd, "day", "[]")) {
          applicableRate = parseFloat(floatingRate[i].base_rate);
          break;
        }
      }

      if (applicableRate !== null) {
        const rate = applicableRate + flatRate;
        const dailyInterestValue = (principal * rate) / 36000; // Calculate daily interest
        cumulativeInterest += dailyInterestValue;

        // Check for payments on the current day
        let principalPaid = 0;
        let interestPaid = 0;

        const formattedDate = current.format("DD/MM/YYYY");
        const payment = paymentDetails?.find((p) => {
          const payDate = moment(p.paydate).format("DD/MM/YYYY");
          return payDate === formattedDate;
        });

        if (payment) {
          const paymentAmount = parseFloat(payment.paymentAmount);

          if (payment.paymentType === "Principle") {
            principalPaid = paymentAmount;
            principal -= principalPaid;
          } else if (payment.paymentType === "Interest") {
            interestPaid = paymentAmount;
            cumulativeInterest -= interestPaid;
          }
        }

        const entry = {
          date: formattedDate,
          principal: principal?.toFixed(2),
          principalPaid: principalPaid?.toFixed(2),
          interestPaid: interestPaid?.toFixed(2),
          Amount: (principal + cumulativeInterest)?.toFixed(2),
          [formattedDate]: cumulativeInterest?.toFixed(2),
        };
        dailyInterest.push(entry);
      } else {
        console.warn(
          `No applicable rate found for ${current.format("DD/MM/YYYY")}`,
        );
      }
    }

    return dailyInterest;
  };

  const handleAddtionalDocs = async () => {
    try {
      const formData = new FormData();
      selectedImage?.map((item) => formData.append("files", item, item?.name));
      const dataToSend = {
        files: formData,
      };
      const res = await patchFormData({
        endpoint: "funding/updateFunding",
        token: cookies.t,
        params: {
          id: fundedDataById?._id,
        },
        data: Obj2FormData(dataToSend, formData),
      });
      if (res) {
        toast.success("Documents Updated Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      setSelectedImage();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrap}>
        <div>
          <Heading className="HeadingSubStyle">
            Attach Additional Support Files
          </Heading>
          <ImageSelector
            placeholder="Upload Supporting Documents"
            handleFile={(file) => {
              handleMultipleImages(file);
            }}
            multiple
            // customStyles={{ width: "23.9%" }}
          />
          <div className={style.uploadImageWrap}>
            {selectedImage?.map((item) => {
              return (
                <div className="w-100">
                  <div className={style.imagesNameStyle}>
                    <span className={style.imagesName}>{item?.name}</span>
                    <div
                      className={style.closeIcon}
                      onClick={() => {
                        handleRemoveImage(item);
                      }}
                    >
                      <Close />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <CustomButton
            text="Save"
            type="btn-primary"
            size="btn-lg"
            handleClick={() => {
              handleAddtionalDocs();
            }}
          />
        </div>
      </div>
      <div className={style.fundedViewRightWrap}>
        <Heading className="HeadingSubStyle">Accrued Interest Table</Heading>
        <div className={style.DateRangeWrap}>
          <div style={{ position: "relative", width: "40%" }}>
            <DateSelector
              placeholder="Start Date"
              onChange={(option) => {
                setInterestData((prev) => ({
                  ...prev,
                  startDate: option,
                }));
                setRunQuery(false);
              }}
              minDateData={
                fundedDataById?.transaction_type === "Supplier" ||
                fundedDataById?.transaction_type === "Supplier Credit-1" ||
                fundedDataById?.transaction_type === "Client" ||
                fundedDataById?.transaction_type === "Revolver" ||
                fundedDataById?.transaction_type === "Note" ||
                fundedDataById?.transaction_type === "Supplier Credit" ||
                fundedDataById?.transaction_type === "Obligor"
                  ? moment
                      .utc(fundedDataById?.funded_date)
                      .add(1, "day")
                      .toISOString()
                  : fundedDataById?.invoice_date
              }
              selectedDate={interestDate.startDate}
            />
          </div>
          <div style={{ position: "relative", width: "40%" }}>
            <DateSelector
              placeholder="End Date"
              onChange={(option) => {
                setInterestData((prev) => ({
                  ...prev,
                  endDate: option,
                }));
                setRunQuery(false);
              }}
              minDateData={interestDate.startDate}
              selectedDate={interestDate.endDate}
            />
          </div>
        </div>
        <CustomButton
          text="Run"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            setRunQuery(true);
          }}
        />
        {runQuery && (
          <div className="mt-4">
            <table className="w-100 border-collapse">
              <thead>
                <tr className="bg-gray">
                  <th colSpan="3" className="border p-2 text-left">
                    Principal
                  </th>
                  <th colSpan="3" className="border p-2 text-left">
                    Interest
                  </th>
                </tr>
                <tr className="bg-gray">
                  <th className="border p-2 text-left">Date</th>
                  <th className="border p-2 text-left">Principal Remaining</th>
                  <th className="border p-2 text-left">Principal Paid</th>
                  <th className="border p-2 text-left">Interest Paid</th>
                  <th className="border p-2 text-left">Interest Accrued</th>
                  <th className="border p-2 text-left">Amount to Close</th>
                </tr>
              </thead>
              <tbody>
                {dailyInterest?.map((row, index) => {
                  return (
                    <tr className="" key={index}>
                      <td className="border p-2">{row.date}</td>
                      <td className="border p-2">
                        {NumberFormatAndAddZero(fundedDataById?.cost_value)}
                      </td>
                      <td className="border p-2">{0.0}</td>
                      <td className="border p-2">{0.0}</td>
                      <td className="border p-2">{row[row.date]}</td>
                      <td className="border p-2">
                        {fundedDataById?.transaction_type === "Revolver" ||
                        fundedDataById?.transaction_type === "Note" ? (
                          row["Amount"]
                        ) : (
                          <>
                            {NumberFormatAndAddZero(
                              parseFloat(fundedDataById?.face_value),
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
export default AdditionalDocUpload;
