import { Box } from "@mui/system";
import { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { confimationStyles } from "../../../../../../assets/styles/components/toast";
import { NumberFormatAndAddZero } from "../../../../../../helpers/capatalizeLetters";
import { getData, patchData } from "../../../../../../services";
import { getPandaApi } from "../../../../../../services/pandaDocs";
import CustomButton from "../../../../../atoms/CustomButton";
import NoContentCard from "../../../../../atoms/NoContentCard";
import Pagination from "../../../../../atoms/Pagination";
import TabStrip from "../../../../../atoms/tabstrip";
import CustomDatagrid from "../../../../../molecules/CustomDatagrid";
import style from "./index.module.scss";

const SubmittedModal = ({
  modalStateSubmitted,
  setModalStateSubmitted,
  getAllFundingStatus,
}) => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const [fundingData, setFundingData] = useState({});
  const [clientData, setClientData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [totalCredit, setTotalCredit] = useState();
  const [duesData, setDuesData] = useState([]);
  const [pandaDocsStatus, setPandaDocStatus] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [activeTab, setActiveTab] = useState("Credit info");

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const data = [
    {
      id: 1,
      label: "Credit info",
      view: (
        <CreditInfo
          fundingData={fundingData}
          clientData={clientData}
          totalCredit={totalCredit}
          pandaDocsStatus={pandaDocsStatus}
        />
      ),
    },
    {
      id: 1,
      label: "See Dues",
      view: (
        <SeeObligorDues
          fundingData={fundingData}
          duesData={duesData}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
        />
      ),
    },
  ];

  const getFundingById = async () => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingById",
        token: cookies.t,
        params: {
          id: modalStateSubmitted.modalId,
        },
      });
      setFundingData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const getClientById = async (id) => {
    try {
      const res = await getData({
        endpoint: "couterParty/getCounterPartyById",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setClientData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };
  useEffect(() => {
    if (
      fundingData?.transaction_type === "Supplier Credit" ||
      fundingData?.transaction_type === "Supplier Credit-1"
    ) {
      getClientById(fundingData?.supplier_id);
    } else if (
      fundingData?.transaction_type === "Note" ||
      fundingData?.transaction_type === "Revolver"
    ) {
      getClientById(fundingData?.client_id);
    } else {
      getClientById(fundingData?.obligor_id);
    }
  }, [fundingData?.client_id]);

  useEffect(() => {
    if (modalStateSubmitted.modalId) {
      getFundingById();
    }
  }, [modalStateSubmitted.modalId]);

  useEffect(() => {
    if (fundingData) {
      getDueData(fundingData);
    }
  }, [fundingData, itemsPerPage, currentPage]);

  useEffect(() => {
    if (fundingData?.documentsInvoice?.docInvoiceId) {
      handlePandaDocsStatus();
    }
  }, [fundingData?.documentsInvoice?.docInvoiceId]);

  const getDueData = async (fundingData) => {
    try {
      const res = await getData({
        endpoint: "funding/getFundingClientIdWithPagination",
        params: {
          id: fundingData.client_id,
          status: "Funded",
          trans_type: "Client",
          page: currentPage,
          limit: itemsPerPage,
        },
        token: cookies.t,
      });
      setDuesData(res?.data);
      console.log("res?.data", res?.data);
      const totalCostValue = res?.data?.fundedData?.reduce((sum, item) => {
        const faceValue = parseFloat(item?.face_value) || 0;
        return sum + faceValue; // Add parsed value to the sum
      }, 0);
      console.log("totalCostValue", totalCostValue);
      setTotalCredit(totalCostValue);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlePandaDocsStatus = async () => {
    const response = await getPandaApi({
      endpoint: `https://api.pandadoc.com/public/v1/documents/${fundingData?.documentsInvoice?.docInvoiceId}`,
      params: {},
      token: process.env.REACT_APP_PANDADOC_ID,
    });
    setPandaDocStatus(response);
  };

  const handleRejectFunding = async () => {
    try {
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateSubmitted.modalId,
        },
        data: {
          status: "Archive",
        },
      });
      if (res) {
        toast.success("Funding Rejected Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
      setModalStateSubmitted(() => ({
        isModalOpen: false,
      }));
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleApproveFunding = async () => {
    try {
      setIsLoading(true);
      const res = await patchData({
        endpoint: "funding/UpdateFundingStatus",
        token: cookies.t,
        params: {
          id: modalStateSubmitted.modalId,
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
        data: {
          status: "Approved",
        },
      });
      if (res) {
        toast.success("Funding Approved Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        setIsLoading(false);
      }
      setModalStateSubmitted(() => ({
        isModalOpen: false,
      }));
      getAllFundingStatus();
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <>
      <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>

      <div
        className={`${style.buttonStyle} d-flex w-100 justify-content-between`}
      >
        {/* <CustomButton
          text="Approve"
          type="btn-primary"
          size="btn-lg"
          handleClick={() => {
            handleApproveFunding();
          }}
        /> */}
        <div className="d-flex gap-3">
          <button
            className="btn-primary btn px-5"
            type="submit"
            onClick={() => {
              handleApproveFunding();
            }}
          >
            Approve
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm ms-2 text-light"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>
          <CustomButton
            text="Reject"
            type="btn-outline-primary"
            size="btn"
            classes={"px-5"}
            handleClick={() => {
              handleRejectFunding();
            }}
          />
        </div>

        <CustomButton
          text="Cancel"
          type="btn-outline-dark"
          size="btn"
          classes={"px-5"}
          handleClick={() => {
            setModalStateSubmitted(() => ({
              isModalOpen: false,
            }));
          }}
        />
      </div>
    </>
  );
};
export default SubmittedModal;
//Sub module
const CreditInfo = ({
  fundingData,
  clientData,
  totalCredit,
  pandaDocsStatus,
}) => {
  return (
    <>
      <div>
        <div className="row">
          <label className={style.terms}>Asset Info</label>

          <div className="col-6">
            <div className={style.label}>Cost</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {NumberFormatAndAddZero(fundingData?.cost_value)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Face Value</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {NumberFormatAndAddZero(parseFloat(fundingData.face_value))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Currency</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{fundingData?.currency}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Schedule status</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{pandaDocsStatus.status}</div>
          </div>
        </div>
        <div className="row">
          <label className={style.terms}>Obligor Information</label>
          <div className="col-6">
            <div className={style.label}>Name</div>
          </div>
          <div className="col-6">
            <div className={style.value}>{clientData?.counter_name}</div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Internal Credit Limit</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {NumberFormatAndAddZero(clientData?.credit_limit)}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Credit Used</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {NumberFormatAndAddZero(parseFloat(totalCredit))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className={style.label}>Credit Remaining</div>
          </div>
          <div className="col-6">
            <div className={style.value}>
              {NumberFormatAndAddZero(
                parseFloat(clientData?.credit_limit) - parseFloat(totalCredit),
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const SeeObligorDues = ({
  duesData,
  currentPage,
  setCurrentPage,
  itemsPerPage,
  setItemsPerPage,
}) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Content Found"
          subtitle=""
          type="See Dues"
          classes="d-flex align-items-center"
          height={"7rem"}
          fontSize={"18px"}
        />
      </>
    );
  });
  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.client_name} className="line-truncate">
            {params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "obligor_name",
      headerName: "Obligor",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.obligor_name} className="line-truncate">
            {params?.data?.obligor_name
              ? params?.data?.obligor_name
              : params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "supplier_name",
      headerName: "Supplier",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.supplier_name} className="line-truncate">
            {params?.data?.supplier_name
              ? params?.data?.supplier_name
              : params?.data?.client_name}
          </div>
        );
      },
    },
    {
      field: "cost_value",
      headerName: "Cost Value",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.cost_value}>
            {NumberFormatAndAddZero(parseFloat(params?.data?.cost_value))}
          </div>
        );
      },
    },
    {
      field: "face_value",
      headerName: "Face Value",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.face_value}>
            {NumberFormatAndAddZero(parseFloat(params?.data?.face_value))}
          </div>
        );
      },
    },
    {
      field: "currency",
      headerName: "Currency",
      flex: 0.8,
      cellRenderer: (params) => {
        return <div>{params?.data?.currency}</div>;
      },
    },
    {
      field: "transaction_type",
      headerName: "Transaction Type",
      flex: 0.8,
      cellRenderer: (params) => {
        return (
          <div title={params?.data?.transaction_type}>
            {params?.data?.transaction_type}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <CustomDatagrid
        rows={duesData?.fundedData}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        tHeight="23rem"
        onCellClick={(data) => {
          navigate(`/dashboard/funding/view/${data?.data?._id}`);
        }}
      />
      {duesData?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={duesData?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
    </>
  );
};
