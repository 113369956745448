import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { NumberFormatAndAddZero } from "../../../../../../helpers/capatalizeLetters";
import { findRateForMonth } from "../../../../../../helpers/finalDateCalculate";
import { getData } from "../../../../../../services";
import CustomButton from "../../../../../atoms/CustomButton";
import { Heading } from "../../../../../atoms/Heading";
import { DateSelector } from "../../../../../atoms/MonthYearSelector";
import style from "../index.module.scss";

const InterestDetails = ({ fundedDataById, pricingData }) => {
  const [cookies] = useCookies(["t"]);
  const [interestDate, setInterestData] = useState({
    startDate: null,
    endDate: null,
  });
  const [interestRateFloating, setInterestRateFloating] = useState();
  const [interestRateFlat, setInterestFlat] = useState();
  const [dailyInterest, setDailyInterest] = useState([]);
  const [runQuery, setRunQuery] = useState(false);
  const [fundingData, setFundingData] = useState();

  const getInterestRate = async (rateID) => {
    try {
      const res = await getData({
        endpoint: "rates/getRateById",
        token: cookies.t,
        params: { id: rateID },
      });
      setInterestRateFloating(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (pricingData?.rate_type === "Floating") {
      getInterestRate(pricingData?.base_rate);
    } else if (pricingData?.rate_type === "Flat") {
      setInterestFlat(pricingData?.top_rate);
    }
  }, [pricingData]);

  const [paymentDetails, setPaymentDetails] = useState();

  useEffect(() => {
    if (interestRateFloating?.rates && runQuery) {
      if (
        fundedDataById?.transaction_type === "Revolver" ||
        fundedDataById?.transaction_type === "Note"
      ) {
        const tempTotalInterest = findRateForMonth(
          interestRateFloating?.rates,
          new Date(fundedDataById?.funded_date),
        );
        const tempDailyInterest = calculateDailyCompoundInterest(
          parseFloat(fundedDataById?.cost_value),
          parseFloat(tempTotalInterest?.base_rate) +
            parseFloat(pricingData?.top_rate),
          moment.utc(fundedDataById?.funded_date).add(1, "day").toISOString(),
          interestDate.endDate,
          paymentDetails,
        );

        const filteredData = tempDailyInterest.filter((item) => {
          return (
            item.date >= interestDate.startDate &&
            item.date <= interestDate?.endDate
          );
        });
        setDailyInterest(tempDailyInterest);
      } else {
        const tempTotalInterest = findRateForMonth(
          interestRateFloating?.rates,
          new Date(fundedDataById?.funded_date),
        );
        const tempDailyInterest = calculateDailyCompoundInterest(
          parseFloat(fundedDataById?.cost_value),
          parseFloat(tempTotalInterest?.base_rate) +
            parseFloat(pricingData?.top_rate),
          moment.utc(fundedDataById?.funded_date).add(1, "day").toISOString(),
          interestDate.endDate,
          paymentDetails,
        );
        const filteredData = tempDailyInterest.filter((item) => {
          return (
            item.date >= interestDate.startDate &&
            item.date <= interestDate?.endDate
          );
        });
        setDailyInterest(tempDailyInterest);
      }
      // }
    } else if (interestRateFlat && runQuery) {
      const tempDailyInterest = calculateDailyCompoundInterest(
        parseFloat(fundedDataById?.cost_value),
        pricingData?.top_rate,
        moment.utc(fundedDataById?.funded_date).add(1, "day").toISOString(),
        interestDate.endDate,
        paymentDetails,
      );
      setDailyInterest(tempDailyInterest);
    }
  }, [interestRateFloating, runQuery, interestRateFlat]);

  useEffect(() => {
    if (fundedDataById?.documents) {
      setFundingData(fundedDataById);
      setPaymentDetails(fundedDataById?.paymentHistory);
    }
  }, [fundedDataById?.documents]);

  const calculateDailyCompoundInterest = (
    principal,
    annualRate,
    startDate,
    endDate,
    paymentDetails,
  ) => {
    const start = new Date(
      Date.UTC(
        new Date(startDate).getUTCFullYear(),
        new Date(startDate).getUTCMonth(),
        new Date(startDate).getUTCDate(),
      ),
    );

    const end = new Date(
      Date.UTC(
        new Date(endDate).getUTCFullYear(),
        new Date(endDate).getUTCMonth(),
        new Date(endDate).getUTCDate(),
      ),
    );
    console.log("endDate (UTC):", end.toISOString());
    const dailyInterestRate = annualRate / 36000;

    let currentDate = new Date(start);
    const dailyInterest = principal * dailyInterestRate;
    let accumulatedInterest = dailyInterest;
    const results = [];

    while (currentDate <= end) {
      const formattedDate = currentDate.toISOString().split("T")[0];
      const result = {};
      result.date = formattedDate;

      let principalPaid = 0;
      let interestPaid = 0;

      const payment = paymentDetails?.find((p) => {
        const payDate = new Date(p?.paydate);
        return payDate?.toISOString()?.split("T")[0] === formattedDate;
      });

      if (payment) {
        const paymentAmount = parseFloat(payment.paymentAmount);

        if (payment.paymentType === "Principle") {
          principalPaid = paymentAmount;
          principal -= principalPaid;
        } else if (payment.paymentType === "Interest") {
          interestPaid = paymentAmount;
          accumulatedInterest -= interestPaid;
        }
      }

      result.principal = principal?.toFixed(2);
      result.principalPaid = principalPaid?.toFixed(2);
      result.interestPaid = interestPaid?.toFixed(2);
      result[formattedDate] = accumulatedInterest?.toFixed(2);
      result["Amount"] = (
        parseFloat(principal) + parseFloat(result[formattedDate])
      )?.toFixed(2);
      results.push(result);

      accumulatedInterest += dailyInterest;
      // currentDate.setDate(currentDate.getDate() + 1);
      currentDate = new Date(
        Date.UTC(
          currentDate.getUTCFullYear(),
          currentDate.getUTCMonth(),
          currentDate.getUTCDate() + 1,
        ),
      );
    }
    return results;
  };

  const calculateDailyCompoundInterestFloating = ({
    principal,
    floatingRate,
    flatRate,
    startDate,
    endDate,
    paymentDetails,
  }) => {
    const start = moment(startDate); // Handles ISO format directly
    const end = moment(endDate); // Handles ISO format directly

    if (!start.isValid() || !end.isValid()) {
      console.error("Invalid startDate or endDate.");
      return [];
    }

    const dailyInterest = [];
    let cumulativeInterest = 0;

    for (
      let current = start.clone();
      current.isBefore(end) || current.isSame(end, "day");
      current.add(1, "days")
    ) {
      let applicableRate = null;

      // Find the floating rate applicable for the current day
      for (let i = 0; i < floatingRate.length; i++) {
        const rateStart = moment(floatingRate[i].date);
        const rateEnd =
          i + 1 < floatingRate.length ? moment(floatingRate[i + 1].date) : end;

        if (current.isBetween(rateStart, rateEnd, "day", "[]")) {
          applicableRate = parseFloat(floatingRate[i].base_rate);
          break;
        }
      }

      if (applicableRate !== null) {
        const rate = applicableRate + flatRate;
        const dailyInterestValue = (principal * rate) / 36000; // Calculate daily interest
        cumulativeInterest += dailyInterestValue;

        // Check for payments on the current day
        let principalPaid = 0;
        let interestPaid = 0;

        const formattedDate = current.format("DD/MM/YYYY");
        const payment = paymentDetails?.find((p) => {
          const payDate = moment(p.paydate).format("DD/MM/YYYY");
          return payDate === formattedDate;
        });

        if (payment) {
          const paymentAmount = parseFloat(payment.paymentAmount);

          if (payment.paymentType === "Principle") {
            principalPaid = paymentAmount;
            principal -= principalPaid;
          } else if (payment.paymentType === "Interest") {
            interestPaid = paymentAmount;
            cumulativeInterest -= interestPaid;
          }
        }

        const entry = {
          date: formattedDate,
          principal: principal?.toFixed(2),
          principalPaid: principalPaid?.toFixed(2),
          interestPaid: interestPaid?.toFixed(2),
          Amount: (principal + cumulativeInterest)?.toFixed(2),
          [formattedDate]: cumulativeInterest?.toFixed(2),
        };
        dailyInterest.push(entry);
      } else {
        console.warn(
          `No applicable rate found for ${current.format("DD/MM/YYYY")}`,
        );
      }
    }

    return dailyInterest;
  };

  return (
    <div className={style.bothsidewrap}>
      <div className={style.fundedViewleftWrap}>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" style={{ width: "45%" }}>
                  Asset Information
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Transaction Type</td>
                <td className={style.value}>
                  {fundedDataById?.transaction_type === "Obligor"
                    ? "FA"
                    : fundedDataById?.transaction_type}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Term</td>
                <td className={style.value}>{fundedDataById?.payment_terms}</td>
              </tr>
              <tr>
                <td className={style.label}>Cost</td>
                <td className={style.value}>
                  {NumberFormatAndAddZero(fundedDataById?.cost_value)}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Face Value</td>
                <td className={style.value}>
                  {NumberFormatAndAddZero(
                    parseFloat(fundedDataById?.face_value),
                  )}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Currency</td>
                <td className={style.value}>{fundedDataById?.currency}</td>
              </tr>
              {fundedDataById?.transaction_type === "Supplier" && (
                <tr>
                  <td className={style.label}>Invoice Date</td>
                  <td className={style.value}>
                    {" "}
                    {moment.utc(fundedDataById?.createdAt).format("YYYY-MM-DD")}
                  </td>
                </tr>
              )}
              {fundedDataById?.transaction_type !== "Supplier" && (
                <tr>
                  <td className={style.label}>Invoice Date</td>
                  <td className={style.value}>
                    {" "}
                    {moment
                      .utc(fundedDataById?.invoice_date)
                      .format("YYYY-MM-DD")}
                  </td>
                </tr>
              )}
              {fundedDataById?.transaction_type === "Supplier" ||
              fundedDataById?.transaction_type === "Supplier Credit-1" ? (
                <tr>
                  <td className={style.label}>Due Date</td>
                  <td className={style.value}>
                    {moment.utc(fundedDataById?.due_date).format("YYYY-MM-DD")}
                  </td>
                </tr>
              ) : (
                <>
                  {fundedDataById?.due_date && (
                    <tr>
                      <td className={style.label}>Due Date</td>
                      <td className={style.value}>
                        {moment
                          .utc(fundedDataById?.due_date)
                          .format("YYYY-MM-DD")}
                      </td>
                    </tr>
                  )}
                </>
              )}
              <tr>
                <td className={style.label}>Funding Date</td>
                <td className={style.value}>
                  {moment.utc(fundedDataById?.funded_date).format("YYYY-MM-DD")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <table className="w-100 border-collapse">
            <thead>
              <tr className="bg-gray">
                <th colSpan="4" className="border p-2 text-left">
                  Payment
                </th>
              </tr>
              <tr className="bg-gray">
                <th className="border p-2 text-left">Payment Amount</th>
                <th className="border p-2 text-left">Currency</th>
                <th className="border p-2 text-left">Date</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails?.map((item) => {
                return (
                  <tr>
                    <td className="border p-2">{item?.paymentAmount}</td>
                    <td className="border p-2">{item?.currency}</td>
                    <td className="border p-2">
                      {moment.utc(item?.paydate).format("YYYY-MM-DD")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <table class="table">
            <thead>
              <tr>
                <th scope="col" style={{ width: "45%" }}>
                  Pricing Information
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className={style.label}>Name</td>
                <td className={style.value}>{pricingData?.name}</td>
              </tr>
              <tr>
                <td className={style.label}>Description</td>
                <td className={style.value}>{pricingData?.description}</td>
              </tr>
              <tr>
                <td className={style.label}>Top Rate</td>
                <td className={style.value}>{pricingData?.top_rate}</td>
              </tr>
              <tr>
                <td className={style.label}>Base Rate</td>
                <td className={style.value}>
                  {pricingData?.base_rate_name || " "}
                </td>
              </tr>
              <tr>
                <td className={style.label}>Days</td>
                <td className={style.value}>{pricingData?.days}</td>
              </tr>
              <tr>
                <td className={style.label}>Advance Rate</td>
                <td className={style.value}>{pricingData?.advance_rate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className={style.fundedViewRightWrap}>
        <Heading className="HeadingSubStyle">Accrued Interest Table</Heading>
        <div className={style.DateRangeWrap}>
          <div style={{ position: "relative", width: "40%" }}>
            <DateSelector
              placeholder="Start Date"
              onChange={(option) => {
                setInterestData((prev) => ({
                  ...prev,
                  startDate: option,
                }));
                setRunQuery(false);
              }}
              minDateData={
                fundedDataById?.transaction_type === "Supplier" ||
                fundedDataById?.transaction_type === "Supplier Credit-1" ||
                fundedDataById?.transaction_type === "Client" ||
                fundedDataById?.transaction_type === "Revolver" ||
                fundedDataById?.transaction_type === "Note" ||
                fundedDataById?.transaction_type === "Supplier Credit" ||
                fundedDataById?.transaction_type === "Obligor"
                  ? moment
                      .utc(fundedDataById?.funded_date)
                      .add(1, "day")
                      .toISOString()
                  : fundedDataById?.invoice_date
              }
              selectedDate={interestDate.startDate}
            />
          </div>
          <div style={{ position: "relative", width: "40%" }}>
            <DateSelector
              placeholder="End Date"
              onChange={(option) => {
                setInterestData((prev) => ({
                  ...prev,
                  endDate: option,
                }));
                setRunQuery(false);
              }}
              minDateData={interestDate.startDate}
              selectedDate={interestDate.endDate}
            />
          </div>

          <CustomButton
            text="Run"
            type="btn-primary"
            size="btn-lg"
            handleClick={() => {
              setRunQuery(true);
            }}
          />
        </div>

        {runQuery && (
          <div className={` mt-4 ${style.InterestDetailsWrap}`}>
            <table className="w-100 border-collapse">
              <thead>
                <tr className="bg-gray">
                  <th colSpan="3" className="border p-2 text-left">
                    Principal
                  </th>
                  <th colSpan="3" className="border p-2 text-left">
                    Interest
                  </th>
                </tr>
                <tr className="bg-gray">
                  <th className="border p-2 text-left">Date</th>
                  <th className="border p-2 text-left">Principal Remaining</th>
                  <th className="border p-2 text-left">Principal Paid</th>
                  <th className="border p-2 text-left">Interest Paid</th>
                  <th className="border p-2 text-left">Interest Accrued</th>
                  <th className="border p-2 text-left">Amount to Close</th>
                </tr>
              </thead>
              <tbody>
                {dailyInterest?.map((row, index) => {
                  return (
                    <tr className="" key={index}>
                      <td className="border p-2">{row.date}</td>
                      <td className="border p-2">
                        {NumberFormatAndAddZero(row?.principal)}
                      </td>
                      <td className="border p-2">
                        {NumberFormatAndAddZero(row["principalPaid"])}
                      </td>
                      <td className="border p-2">
                        {NumberFormatAndAddZero(row["interestPaid"])}
                      </td>
                      <td className="border p-2">
                        {NumberFormatAndAddZero(row[row.date])}
                      </td>
                      <td className="border p-2">
                        {fundedDataById?.transaction_type === "Revolver" ||
                        fundedDataById?.transaction_type === "Note" ||
                        fundedDataById?.transaction_type === "Obligor" ||
                        fundedDataById?.transaction_type ===
                          "Supplier Credit-1" ? (
                          NumberFormatAndAddZero(row["Amount"])
                        ) : (
                          <>
                            {NumberFormatAndAddZero(
                              parseFloat(fundedDataById?.face_value),
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
export default InterestDetails;
