import React, { memo, useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import { convenantFrequency } from "../../../../helpers/staticData";
import { getData } from "../../../../services";
import { downloadReport } from "../../../../services/downloadService";
import CustomButton from "../../../atoms/CustomButton";
import { Heading } from "../../../atoms/Heading";
import NoContentCard from "../../../atoms/NoContentCard";
import Pagination from "../../../atoms/Pagination";
import Search from "../../../atoms/Search";
import Upload from "../../../icons/Upload";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import style from "./index.module.scss";

const ReportsCovenants = () => {
  const [cookies] = useCookies(["t", "roleGroup"]);
  const navigate = useNavigate();
  const accessData = useSelector((state) => state?.accessData?.accessData);
  const roleData = accessData?.Counterparties;
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [counterpartyData, setCounterPartData] = useState([]);
  const [covenantData, setCovenantData] = useState([]);
  const [filters, setFilters] = useState({});
  const [loader, setLoader] = useState(true);
  const getCovenantData = async () => {
    setLoader(true);
    try {
      const res = await getData({
        endpoint: "/covenant",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          frequency: filters?.["Frequency"],
          group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
        },
      });
      setCovenantData(res?.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    setLoader(false);
    getCovenantData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const columns = [
    {
      field: "client_name",
      headerName: "Client",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.client_name}</div>;
      },
    },
    {
      field: "name",
      headerName: "Requirement",
      flex: 1,
      sortable: true,
      // valueGetter: (param, row) => {
      //   return param?.row?.name;
      // },
      cellRenderer: (params) => {
        return <div className="line-truncate">{params?.data?.name}</div>;
      },
    },
    {
      field: "long_description",
      headerName: "Requirement(Detail)",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return (
          <div className="line-truncate">{params?.data?.long_description}</div>
        );
      },
    },
    {
      field: "frequency",
      headerName: "Frequency",
      flex: 1,
      sortable: true,
      cellRenderer: (params) => {
        return <div>{params?.data?.frequency}</div>;
      },
    },
    {
      field: "monthly_shift",
      headerName: "Shift",
      flex: 1,
      type: "number",
      sortable: true,
      headerAlign: "left",
      align: "left",
      cellRenderer: (params) => {
        return <div>{params?.data?.monthly_shift}</div>;
      },
    },
  ];

  const CustomNoRowsOverlay = memo(() => {
    return (
      !loader && (
        <>
          <NoContentCard
            title="No Covenants created yet!"
            tag="Add Counterparties"
            type="Counterparty"
            classes="d-flex align-items-center"
          />
        </>
      )
    );
  });

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    Frequency: convenantFrequency,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "covenant/export",
      token: cookies.t,
      fileName: "Covenant",
      params: {
        search: searchData,
        frequency: filters?.["Frequency"],
        group_id: cookies.roleGroup ? atob(cookies.roleGroup) : [],
      },
    });

    if (res) {
      toast.success("Covenants Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className={style.headingSearchWrap}>
        <div>
          <Heading className="HeadingStyle">Covenants</Heading>
        </div>
        <div className={style.searchButton}>
          <Search
            placeholder="Search by name"
            onSearchEmit={(data) => {
              setSearchData(data);
            }}
          />

          {roleData && roleData?.export && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}
          <FilterComponent
            filterData={filterData}
            onFilterChange={(filters) => {
              setFilters(filters);
            }}
          />
        </div>
      </div>

      <CustomDatagrid
        rows={covenantData?.[0]?.data || []}
        getRowId={(row) => row?.unique_row_id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        loader={loader}
        isRowsVisible
        isValueVisible
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={covenantData?.[0]?.pageDetails?.count}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
};

export default ReportsCovenants;
